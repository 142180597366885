import request from '@/utils/request'
import requests from '@/utils/requests'


// 设置个人总表/户表查看缓存接口
export  function saveisTotalMeter(data) {
  return request({
    url: '/HdybUserOperateRelationTbls/AddAndUpdate',
    method: 'post',
    data
  })
}

// 基础资料接口
  // 获取用户id
  export function getUserId(token) {
    return request({
      url: '/check/GetUserId',
      method: 'get',
      params: { token }
    })
  }
  // 全局缓存变量 - 所有List(用户、区域、项目)
  export function getAllList(params) {
    return request({
      url: '/HdybBaseData/GetWebInitBaseData',
      method: 'get',
      params
    })
  }

  // 全局缓存变量 - 字典分类
  export function getAllCategorys(params) {
    return request({
      url: '/Categorys/SearchAllTypeList',
      method: 'get',
      params
    })
  }
  // 全局缓存变量 - 全部用户
  export function getAllUser(params) {
    return request({
      url: '/Roles/Load',
      method: 'get',
      params
    })
  }

  // 腾讯地图 - 腾讯地图根据keywrod返回地址
  export  function getTencentMapList(data) {
    return request({
      url: '/HdybNetData/GetTencentMapList',
      method: 'post',
      data
    })
  }
  // 腾讯地图 - 腾讯地图通过地址关键字查询地图信息
  export  function getTencentMapInformation(data) {
    return request({
      url: '/HdybNetData/GetLocationByKeyword',
      method: 'post',
      data
    })
  }

  //首页图表接口
    // 获取首页顶部概览数据
    export function homeGetOverview(params) {
      return request({
        url: '/HdybHomePage/GetMeterReadingData',
        method: 'get',
        params
      })
    }

    // 获取首页顶部折线图
    export function homeGetRealChart(params) {
      return request({
        url: '/HdybHomePage/GetMeterReadDayData',
        method: 'get',
        params
      })
    }
    

    // 获取首页中部环形图
    export function homeGetRingChart(params) {
      return request({
        url: '/HdybHomePage/GetMeterReadRateDayData',
        method: 'get',
        params
      })
    }

    // 获取首页中部条形图
    export function homeGetStripesChart(params) {
      return request({
        url: '/HdybHomePage/GetAreaReadingData',
        method: 'get',
        params
      })
    }

    // 获取首页中部柱形图
    export function homeGetBarChart(params) {
      return request({
        url: '/HdybHomePage/GetMeterTypeReadingData',
        method: 'get',
        params
      })
    }

    // 获取首页底部报警记录
    export function homeGetWarning(params) {
      return request({
        url: '/HdybHomePage/GetAlarmLog',
        method: 'get',
        params
      })
    }



  // 组态管理
    // 获取单体图列表
    export function ztglGetList(params) {
      return request({
        url: '/HdybFlowPublishs/Load',
        method: 'get',
        params
      })
    }

    // 添加
    export  function ztglAdd(data) {
      return request({
        url: '/HdybFlowPublishs/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function ztglDelete(data) {
      return request({
        url: '/HdybFlowPublishs/Delete',
        method: 'post',
        data
      })
    }

    // 修改
    export  function ztglModify(data) {
      return request({
        url: '/HdybFlowPublishs/Update',
        method: 'post',
        data
      })
    }

    // 获取单体图历史数据
    export function ztglGetHistoryList(params) {
      return request({
        url: '/HdybFlowHistorys/Load',
        method: 'get',
        params
      })
    }

    // 删除单体图历史数据
    export  function ztglHistoryDelete(data) {
      return request({
        url: '/HdybFlowHistorys/DeleteFlow',
        method: 'post',
        data
      })
    }
  

  // 数据导入
    // 获取数据
    export function sjdrGetList(params) {
      return request({
        url: '/HdybFileImports/Load',
        method: 'get',
        params
      })
    }

    // 数据导入
    export  function sjdrAdd(data) {
      return request({
        url: '/HdybFileImports/UploadFile',
        method: 'post',
        data
      })
    }

    // 删除
    export  function sjdrDel(data) {
      return request({
        url: '/HdybFileImports/LogicDelete',
        method: 'post',
        data
      })
    }

    // 下载模板
    export function sjdrDownModel(params) {
      return request({
        url: '/HdybFileImports/GetTemplatePath',
        method: 'get',
        params
      })
    }

    // 模板上传
    export function uploadModel(data) {
      return request({
        url: '/HdybFileImports/UploadTemplate',
        method: 'post',
        data
      })
    }

    // 导出
    export function sjdrExport(params) {
      return request({
        url: '/HdybFileImports/GetFilePath',
        method: 'get',
        params
      })
    }



  // 类型管理
    // 获取数据
    export function lxglGetList(params) {
      return request({
        url: '/HdybEquipmentTypes/Load',
        method: 'get',
        params
      })
    }

    // 添加
    export  function lxglAdd(data) {
      return request({
        url: '/HdybEquipmentTypes/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function lxglDelete(data) {
      return request({
        url: '/HdybEquipmentTypes/Delete',
        method: 'post',
        data
      })
    }

    // 修改
    export  function lxglModify(data) {
      return request({
        url: '/HdybEquipmentTypes/Update',
        method: 'post',
        data
      })
    }
    

  // 批量处理设备
    // 获取数据
    export function plclsbGetList(data) {
      return request({
        url: '/HdybBatchDeal/Load',
        method: 'post',
        data
      })
    }

    // 批量修改
    export function plclsbOperation(data) {
      return request({
        url: '/HdybBatchDeal/Update',
        method: 'post',
        data
      })
    }

    // 设置表定时采集计划
    export function plclsbTimeCollect(data) {
      return request({
        url: '/HdybBatchDeal/SetMeterOpenJob',
        method: 'post',
        data
      })
    }

    
    


  // 集中器管理
    // 获取数据
    export function jzqglGetList(data) {
      return request({
        url: '/HdybConcentrators/Load',
        method: 'post',
        data
      })
    }

    // 添加
    export  function jzqglAdd(data) {
      return request({
        url: '/HdybConcentrators/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function jzqglDelete(data) {
      return request({
        url: '/HdybConcentrators/Delete',
        method: 'post',
        data
      })
    }

    // 修改
    export  function jzqglModify(data) {
      return request({
        url: '/HdybConcentrators/Update',
        method: 'post',
        data
      })
    }
  
  // 区域管理
    // 获取数据
    export function qyglGetList(data) {
      return request({
        url: '/HdybAreas/Load',
        method: 'post',
        data
      })
    }

    // 添加
    export  function qyglAdd(data) {
      return request({
        url: '/HdybAreas/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function qyglDelete(data) {
      return request({
        url: '/HdybAreas/Delete',
        method: 'post',
        data
      })
    }

    // 修改
    export  function qyglModify(data) {
      return request({
        url: '/HdybAreas/Update',
        method: 'post',
        data
      })
    }

    // 区域调整
    export  function qyglAreaChange(data) {
      return request({
        url: '/HdybAreas/UpdateFatherAreaList',
        method: 'post',
        data
      })
    }

    // 分配用户
    export  function qyglUserDistribution(data) {
      return request({
        url: '/HdybAreas/SetAreaRoles',
        method: 'post',
        data
      })
    }

  // 户表管理 - 住户管理
    // 获取数据
    export function zhglGetList(data) {
      return request({
        url: '/HdybHouseholds/Load',
        method: 'post',
        data
      })
    }

    // 添加
    export  function zhglAdd(data) {
      return request({
        url: '/HdybHouseholds/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function zhglDelete(data) {
      return request({
        url: '/HdybHouseholds/DeleteHousehold',
        method: 'post',
        data
      })
    }

    // 修改
    export  function zhglModify(data) {
      return request({
        url: '/HdybHouseholds/Update',
        method: 'post',
        data
      })
    }

    // 区域调整
    export  function zhglAreaChange(data) {
      return request({
        url: '/HdybHouseholds/UpdateHoldAreaList',
        method: 'post',
        data
      })
    }

  // 户表管理 - 仪表管理
    // 获取数据
    export function ybglGetList(params) {
      return request({
        url: '/HdybMeters/LoadDetail',
        method: 'get',
        params
      })
    }

    // 添加
    export  function ybglAdd(data) {
      return request({
        url: '/HdybMeters/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function ybglDelete(data) {
      return request({
        url: '/HdybMeters/DeleteMeter',
        method: 'post',
        data
      })
    }

    // 修改
    export  function ybglModify(data) {
      return request({
        url: '/HdybMeters/Update',
        method: 'post',
        data
      })
    }

  // 户表管理 - 更换表
    // 修改
    export  function hbglModifyB(data) {
      return request({
        url: '/HdybUserMeters/ChangeUserMeter',
        method: 'post',
        data
      })
    }

  // 总表管理 - 
    // 获取数据
    export function zbglGetList(data) {
      return request({
        url: '/HdybSummaryMeter/LoadTrees',
        method: 'post',
        data
      })
    }

    // 获取右侧子表数据
    export function zbglGetChildrenMeterList(data) {
      return request({
        url: '/HdybSummaryMeter/Load',
        method: 'post',
        data
      })
    }

    // 总表绑定子表
    export function zbglBindChildren(data) {
      return request({
        url: '/HdybSummaryMeter/ChangeSummaryRelevance',
        method: 'post',
        data
      })
    }

    // 更换总表区域
    export function zbglChangeArea(data) {
      return request({
        url: '/HdybSummaryMeter/ChangeSummaryArea',
        method: 'post',
        data
      })
    }

  // 换表记录
    // 获取数据
    export function hbjlGetList(data) {
      return request({
        url: '/HdybChangeMeterRecords/Load',
        method: 'post',
        data
      })
    }


  // 项目管理
    // 获取数据
    export function xmglGetList(params) {
      return request({
        url: '/HdybProjects/Load',
        method: 'get',
        params
      })
    }

    // 添加
    export  function xmglAdd(data) {
      return request({
        url: '/HdybProjects/Add',
        method: 'post',
        data
      })
    }

    // 删除
    export  function xmglDelete(data) {
      return request({
        url: '/HdybProjects/Delete',
        method: 'post',
        data
      })
    }

    // 修改
    export  function xmglModify(data) {
      return request({
        url: '/HdybProjects/Update',
        method: 'post',
        data
      })
    }



  // 数据回收站
    // 获取数据
    export function sjhszGetList(data) {
      return request({
        url: '/HdybDataRecycleBins/Load',
        method: 'post',
        data
      })
    }

    // 恢复
    export  function sjhszRestore(data) {
      return request({
        url: '/HdybDataRecycleBins/RecoveryData',
        method: 'post',
        data
      })
    }

    // 销毁
    export  function sjhszDelete(data) {
      return request({
        url: '/HdybDataRecycleBins/DestroyData',
        method: 'post',
        data
      })
    }


  // 抄表信息
    // 获取数据
    export function cbxxGetList(data) {
      return request({
        url: '/HdybMeterReadViews/Load',
        method: 'post',
        data
      })
    }
    
    // 采集
    export  function cbxxInstructionCj(data) {
      return request({
        url: '/HdybMeterReadViews/AppointCollectNetData',
        method: 'post',
        data
      })
    }

    // 读取
    export  function cbxxInstructionDq(data) {
      return request({
        url: '/HdybMeterReadViews/AppointReadNetData',
        method: 'post',
        data
      })
    }

    // 开阀
    export  function cbxxInstructionKf(data) {
      return request({
        url: '/HdybMeterReadViews/OpenValve',
        method: 'post',
        data
      })
    }

    // 关阀
    export  function cbxxInstructionGf(data) {
      return request({
        url: '/HdybMeterReadViews/CloseValve',
        method: 'post',
        data
      })
    }

    // 指令下发
    export  function cbxxZlxf(data) {
      return request({
        url: '/HdybMeterReadViews/Control',
        method: 'post',
        data
      })
    }

    // 导出
    export  function cbxxExport(data) {
      return request({
        url: '/HdybMeterReadViews/CreateExcelReturnPath',
        method: 'post',
        data
      })
    }

    // 人工抄表
    export  function cbxxRgcb(data) {
      return request({
        url: '/HdybMeterReadViews/ManualMeterReading',
        method: 'post',
        data
      })
    }


  // 地图展示
    // 获取数据
    export function dtzsGetList(params) {
      return request({
        url: '/HdybMapShow/LoadMap',
        method: 'get',
        params
      })
    }

  // 定时采集
    // 获取数据
    export function dscjGetList(data) {
      return request({
        url: '/HdybScheduledAcquisitions/Load',
        method: 'post',
        data
      })
    }

    // 定时采集绑定任务
    export  function dscjBand(data) {
      return request({
        url: '/HdybScheduledAcquisitions/UpdateMeterOpenJob',
        method: 'post',
        data
      })
    }

    // 获取详情
    export function dscjDetails(params) {
      return request({
        url: '/HdybScheduledAcquisitions/GetJobDetail',
        method: 'get',
        params
      })
    }



  // 告警记录
    // 获取数据
    export function gjjlGetList(data) {
      return request({
        url: '/HdybAlarmLogs/Load',
        method: 'post',
        data
      })
    }

    // 处理告警
    export function gjjldealWarning(data) {
      return request({
        url: '/HdybAlarmLogs/HandledAlarm',
        method: 'post',
        data
      })
    }

  // 控制记录
    // 获取数据
    export function kzjlGetList(data) {
      return request({
        url: '/HdybControlRecords/Load',
        method: 'post',
        data
      })
    }

  // 抄表历史
    // 获取数据
    export function cbjlGetList(data) {
      return request({
        url: '/HdybRptMeterReadRecord/Load',
        method: 'post',
        data
      })
    }

    // 导出
    export function cbjlExport(data) {
      return request({
        url: '/HdybRptMeterReadRecord/CreateExcelAndReturnPath',
        method: 'post',
        data
      })
    }

  // 项目抄表率
    // 获取数据
    export function xbcblGetList(data) {
      return request({
        url: '/HdybReportV1/GetMeterReadRateRpt',
        method: 'post',
        data
      })
    }

  // 漏损统计
    // 获取数据
    export function lstjGetList(data) {
      return request({
        url: '/HdybReportV1/GetLeakageRpt',
        method: 'post',
        data
      })
    }
    // 查看详情
    export function lstjDetailsGetList(data) {
      return request({
        url: '/HdybReportV1/GetLeakageDetailRpt',
        method: 'post',
        data
      })
    }

    // 获取所有大表数据
    export function getAllMainMeter(data) {
      return request({
        url: '/HdybBigMeterMenu/GetAllBigMeters',
        method: 'post',
        data
      })
    }

    // 远程搜索 - 所有小表
    export function getAllChildMeter(data) {
      return request({
        url: '/HdybBigMeterMenu/GetAllUserMeters',
        method: 'post',
        data
      })
    }

    // 查询数据数据
    export function getMterSearch(data) {
      return request({
        url: '/HdybBigMeterMenu/GetLeakageData',
        method: 'post',
        data
      })
    }



  // 夜间小流量
    // 获取数据
    export function yjxllGetList(data) {
      return request({
        url: '/HdybReportV1/GetNightFlowRpt',
        method: 'post',
        data
      })
    }
    
  // 用量统计
    // 获取数据 - 区域
    export function yltjQyGetList(data) {
      return request({
        url: '/HdybReportV1/GetAreaIntervalFlowRpt',
        method: 'post',
        data
      })
    }
    // 获取数据 - 明细
    export function qjtjGetList(data) {
      return request({
        url: '/HdybReportV1/GetIntervalFlowRpt',
        method: 'post',
        data
      })
    }


  // 异常记录
    // 获取数据
    export function ycjlGetList(data) {
      return request({
        url: '/HdybExpMeterView/Load',
        method: 'post',
        data
      })
    }

  // 仪表总览
    // 获取仪表基本数据
    export function ybzlGetBasicData(data) {
      return request({
        url: '/HdybMeters/GetSurfaceInfoBySurfaceNum',
        method: 'post',
        data
      })
    }

    // 获取仪表日用量数据
    export function ybzlGetRylData(data) {
      return request({
        url: '/HdybRptMeterReadRecord/GetDayUseData',
        method: 'post',
        data
      })
    }

    




// 华水软件
  // 登陆页面获取租户
  export function loginGetTenants(params) {
    return request({
      url: '/SysTenants/LoadAllTenants',
      method: 'get',
      params
    })
  }

  // 租户管理 - 获取租户列表
  export function zhglGetTenants(params) {
    return request({
      url: '/SysTenants/Load',
      method: 'get',
      params
    })
  }

  // 租户管理 - 新增租户
  export function addTenants(data) {
    return request({
      url: '/SysTenants/Add',
      method: 'post',
      data
    })
  }
  // 租户管理 - 编辑租户
  export function editTenants(data) {
    return request({
      url: '/SysTenants/Update',
      method: 'post',
      data
    })
  }
  // 租户管理 - 删除租户
  export function deleteTenants(data) {
    return request({
      url: '/SysTenants/Delete',
      method: 'post',
      data
    })
  }
  // 租户管理 - 获取租户记录
  export function zhglGetTenantsLog(params) {
    return request({
      url: '/SysTenants/LoadOPLog',
      method: 'get',
      params
    })
  }









  // 华水接口
  // 登陆前校验
  export function beforeLogin(params) {
    return requests({
      url: '/SysTenants/GetByName',
      method: 'get',
      params
    })
  }
  // 基础资料 - 分支机构管理
  export function jczlFzjgglSearch(params) {
    return request({
      url: '/HSAreas/Load',
      method: 'get',
      params
    })
  }
  // 基础资料 - 分支机构管理 - 添加
  export function jczlFzjgglAdd(data) {
    return request({
      url: '/HSAreas/Add',
      method: 'post',
      data
    })
  }
   // 基础资料 - 分支机构管理 -  编辑
   export function jczlFzjgglEdit(data) {
    return request({
      url: '/HSAreas/Update',
      method: 'post',
      data
    })
  }
   // 基础资料 - 分支机构管理 -  删除 
   export function jczlFzjgglDel(data) {
    return request({
      url: '/HSAreas/Delete',
      method: 'post',
      data
    })
  }
  // 基础资料 - 设备列表
  export function jczlSbglSearch(data) {
    return request({
      url: '/HSDevices/Load',
      method: 'post',
      data
    })
  }
  // 基础资料 - 设备列表 - 下发报警配置
  export function jczlSbglLssued(data) {
    return request({
      url: '/HSDevices/SendAlarmSettingsCmd',
      method: 'post',
      data
    })
  }
  // 基础资料 - 设备列表 新增
  export function jczlSbglAdd(data) {
    return request({
      url: '/HSDevices/Add',
      method: 'post',
      data
    })
  }
    // 基础资料 - 设备列表 编辑
    export function jczlSbglEdit(data) {
      return request({
        url: '/HSDevices/Update',
        method: 'post',
        data
      })
    }
       // 基础资料 - 设备列表 删除
       export function jczlSbglDel(data) {
        return request({
          url: '/HSDevices/Delete',
          method: 'post',
          data
        })
      }
      // 设置萤石云
      export function jczlSbglSetYSY(data) {
        return request({
          url: '/HSDevices/SendYSYCmd',
          method: 'post',
          data
        })
      }

  
  // 设备管理 - 设备数据
  export function sbglSbsjSearch(params) {
    return request({
      url: '/HSDevices/LoadWithData',
      method: 'get',
      params
    })
  }
  // 设备管理 - 详情 - 计划列表
  export function sbglJhlbSearch(params) {
    return request({
      url: '/HSDevicePlans/Load',
      method: 'get',
      params
    })
  }

  // 数据管理 - 历史数据
  export function sjglLssjSearch(params) {
    return request({
      url: '/HSDeviceHistoryData/Load',
      method: 'get',
      params
    })
  }
  // 数据管理 - 报警管理
  export function sjglBjglSearch(params) {
    return request({
      url: '/HSAlarmInfos/Load',
      method: 'get',
      params
    })
  }
  // 数据管理 - 巡查记录数据
  export function sjglXcjlSearch(params) {
    return request({
      url: '/HSRoutingInspections/Load',
      method: 'get',
      params
    })
  }

// 历史数据导出

export function lssjExport(data) {
  return request({
    url: '/HSDeviceHistoryData/ExportDeviceHistoryData',
    method: 'post',
    data
  })
}
// 报警导出导出

export function bjglExport(data) {
  return request({
    url: '/HSAlarmInfos/Export',
    method: 'post',
    data
  })
}
// 设备管理责任人配置
export function sbglSetChargePerson(data) {
  return request({
    url: '/HSDevices/SetChargePerson',
    method: 'post',
    data
  })
}
// 设备数据获取统计
export function sbsjsjtj(params) {
  return request({
    url: '/HSDevices/GetDeviceStatistics',
    method: 'get',
    params
  })
}
// 历史数据 补录数据
export function lssjAddOrUpdate(data) {
  return request({
    url: '/HSDeviceHistoryData/AddOrUpdate',
    method: 'post',
    data
  })
}
// 获取产品基本信息和历史数据  /api/HSDevices/GetDeviceInfoAndHistoryData  
export function cpxqGetDeviceInfoAndHistoryData(params) {
  return request({
    url: '/HSDevices/GetDeviceInfoAndHistoryData',
    method: 'get',
    params
  })
}
// 获取实时视频 
export function cpxqGetYSYToken(params) {
  return request({
    url: '/HSDevices/GetYSYToken',
    method: 'get',
    params
  })
}
// 闸门急停 /api/HSDevices/StopGate
export function cpxqStopGate(data) {
  return request({
    url: '/HSDevices/StopGate',
    method: 'post',
    data
  })
}
// 清空监测站历史数据 /api/HSDevices/ClearHistoryData
export function cpxqClearHistoryData(data) {
  return request({
    url: '/HSDevices/ClearHistoryData',
    method: 'post',
    data
  })
}
// 闸门控制新增  /api/HSDevicePlans/Add
export function cpxqHSDevicePlansAdd(data) {
  return request({
    url: '/HSDevicePlans/Add',
    method: 'post',
    data
  })
}
// 闸门 加载计划  //  /api/HSDevicePlans/Load
export function cpxqHSDevicePlansLoad(params) {
  return request({
    url: '/HSDevicePlans/Load',
    method: 'get',
    params
  })
}
// 计划删除
export function cpxqHSDevicePlansDelete(data) {
  return request({
    url: '/HSDevicePlans/Delete',
    method: 'post',
    data
  })
}
// 获取检测站存储数据
export function GetDeviceInfoHistoryDataFromHS(params) {
  return request({
    url: '/HSDevices/GetDeviceInfoHistoryDataFromHS',
    method: 'get',
    params
  })
}
// 更新配置信息
export function cpxqUpdateDeviceConfigInfo(data) {
  return request({
    url: '/HSDevices/UpdateDeviceConfigInfo',
    method: 'post',
    data
  })
}

// 获取升级进度 
export function cpxqQueryDeviceUpdateProgress(data) {
  return request({
    url: '/HSDevices/QueryDeviceUpdateProgress',
    method: 'post',
    data
  })
}
// 获取升级进度  
export function cpxqHSDeviceUpdateRecordsLoad(params) {
  return request({
    url: '/HSDeviceUpdateRecords/Load',
    method: 'get',
    params
  })
}

// 获取实时数据
export function cpxqGetDeviceInfoAndRealData(params) {
  return request({
    url: '/HSDevices/GetDeviceInfoAndRealData',
    method: 'get',
    params
  })
}
// 历史数据删除  /api/HSDeviceHistoryData/Delete
export function lssjDelete(data) {
  return request({
    url: '/HSDeviceHistoryData/Delete',
    method: 'post',
    data
  })
}

// 历史数据批量删除  /api/HSDeviceHistoryData/Delete
export function lssjBatchDelete(data) {
  return request({
    url: '/HSDeviceHistoryData/BatchDelete',
    method: 'post',
    data
  })
}

// 获取每日、每月、每年水量统计  ///api/HSDeviceHistoryData/GetDay_Month_Year_WaterUsed_Statistics
export function GetDay_Month_Year_WaterUsed_Statistics(params) {
  return request({
    url: '/HSDeviceHistoryData/GetDay_Month_Year_WaterUsed_Statistics',
    method: 'get',
    params
  })
}
// 获取分站每日水量统计  ///api/HSDeviceHistoryData/GetEveryDay_WaterUsed_StatisticsForArea
export function GetEveryDay_WaterUsed_StatisticsForArea(params) {
  return request({
    url: '/HSDeviceHistoryData/GetEveryDay_WaterUsed_StatisticsForArea',
    method: 'get',
    params
  })
}

// 获取分站每月水量统计  ///api/HSDeviceHistoryData/GetEveryMonth_WaterUsed_StatisticsForArea
export function GetEveryMonth_WaterUsed_StatisticsForArea(params) {
  return request({
    url: '/HSDeviceHistoryData/GetEveryMonth_WaterUsed_StatisticsForArea',
    method: 'get',
    params
  })
}


// 读取写入指令
export function sbxqSendCommand(data) {
  return request({
    url: '/HSDevices/SendCommand',
    method: 'post',
    data
  })
}
// 读取写入指令 密码特殊处理 第二部获取进度
export function sbxqSendCommandSchedule(data) {
  return request({
    url: '/HSDevices/CheckCommand',
    method: 'post',
    data
  })
}
// 读取写入指令 密码特殊处理 第三部保存配置
export function sbxqSetDeviceConfig(data) {
  return request({
    url: '/HSDevices/SetDeviceConfig',
    method: 'post',
    data
  })
}
// 任务升级 第二部获取进度
export function sbxqQueryDeviceUpdateProgress(data) {
  return request({
    url: '/HSDevices/QueryDeviceUpdateProgress',
    method: 'post',
    data
  })
}
// 任务升级 第三部保存配置
export function sbxqRecordDeviceUpdate(data) {
  return request({
    url: '/HSDevices/RecordDeviceUpdate',
    method: 'post',
    data
  })
}

// 获取可查看的分支机构
export function GetLoadUsersOfTenant(params) {
  return request({
    url: '/Orgs/LoadUsersOfTenant',
    method: 'get',
    params
  })
}