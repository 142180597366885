<template>
  <el-menu class="navbar" mode="horizontal">
		<div class="logo">
			<img class="user-avatar" :src="logo">远传监控平台
		</div>
 
    <el-tooltip  effect="light" :content="sidebar.opened === true?'收缩菜单':'展开菜单'" placement="right-end">
      <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
    </el-tooltip>

    <div class="navbar_right">

      <!-- 个人中心 -->
      <el-dropdown class="avatar-container" @command="handleCommand" trigger="hover" placement="top">
        <div class="avatar-wrapper">
          欢迎您，{{name}}
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu class="user-dropdown" slot="dropdown">
          <el-dropdown-item command="handleGoProfile">
            <!-- <span class="personal_icon iconfont icon-yonghu_zhanghao_wode"></span> -->
            <span class="personal_icon iconfont icon-jiaoseguanli"></span>
            <span>个人中心</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <!-- <span class="personal_icon iconfont icon-pifu_shensemoshi"></span> -->
            <span class="personal_icon iconfont icon-pifu"></span>
            <span>切换主题 <el-switch :active-value="1" :inactive-value="0" class="swith_css" v-model="theme" /></span>
          </el-dropdown-item>
          <el-dropdown-item command="logout" divided>
            <!-- <span class="personal_icon iconfont icon-guanji_tuichu"></span> -->
            <span class="personal_icon iconfont icon-tuichu"></span>
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>


      <!-- 消息模块 -->
      <!-- <el-dropdown class="tips_message" @command="handleCommandMessage" trigger="click" placement="top">
        <span class="el-dropdown-link">
          <el-badge :value="unReadNum" class="badge"  v-if="unReadNum && unReadNum != 0">
            <i class="el-icon-bell" style="font-size:20px;color:#fff;"></i>
          </el-badge>
          <i class="el-icon-bell" style="font-size:20px;color:#fff;" v-else></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="tips_message_dropdowm">
          <el-dropdown-item v-for="(i,index) in messageList" :key="index" :command="beforeHandleCommand('a',i.id,i)">
            <el-badge is-dot class="item_badge" v-if="i.toStatus == 0">
              <h4 class="message_title">{{i.content}}</h4>
            </el-badge>
            <h4 class="message_title" v-else>{{i.content}}</h4>
            <p class="message_time">{{i.createTime}}</p>
          </el-dropdown-item>
          <el-dropdown-item style="text-align:center" :command="beforeHandleCommand('b')" v-if="unReadNum && unReadNum != 0">
            <span class="personal_icon iconfont icon-biaojiweiyidu"></span>
            <span>全部已读</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->


      

      <!-- 聊天系统 -->
      <!-- <div class="navbar_chat">
        <i class="el-icon-s-comment" style="color:#fff;font-size:20px;" @click="openChat"></i>
      </div>
      <el-drawer :visible.sync="drawer" :with-header="false" custom-class="navbar_chat_drawer" >
        <div class="navbar_chat_search">
          <el-autocomplete class="inline-input" value-key="account" v-model="searchChat" :fetch-suggestions="querySearchChat" placeholder="请输入搜索内容" @select="searchChatPerson" ></el-autocomplete>
          <el-button size="small"  type="primary" @click="searchChatPerson" style="margin-left:20px" > 搜索 </el-button>
        </div>
        <el-tabs tab-position="left" v-model="activeName" style="height: calc(100vh - 50px);" type="border-card" @tab-click='changePerson'>
          <el-tab-pane :label="i.account" :name="i.name" v-for="(i,index) in chatPersonList" :key="index">
            <div slot="label" v-if="i.online">
              <span style="margin-right:10px;display:inline-block;width:10px;height:10px;border-radius:10px;background:green;"></span> 
              <el-badge is-dot class="navbar_chat_badge" v-if="i.messageYn">
                {{i.account}}
              </el-badge>
              <span v-else>{{i.account}}</span>
            </div>
            <div class="chat_record" id="chat_record">
              <div v-for="(j,jdx) in recordList" :key="jdx" :class="j.isMy?'myMsg':'otherMsg'">
                <p class="chat_record_time">{{j.fromName}} {{j.createTime}}</p>
                <p class="chat_record_content"> <span>{{j.content}}</span> </p>
              </div>
            </div>
            <div class="send_msg">
              <el-input type="textarea" v-model="sendMsg" :rows="5"></el-input>
              <el-button size="small" v-if="sendMsg.length <= 0" disabled type="primary" class="send_msg_btn">发送</el-button>
              <el-button size="small" v-else  type="primary" @click="onSendMsg" class="send_msg_btn">发送</el-button>
            </div>
            
          </el-tab-pane>
        </el-tabs>
      </el-drawer> -->


      

      <div class="clear"></div>
    </div>
    

    <!-- 消息弹窗 -->
    <el-dialog class="dialog-mini user-dialog" width="30%" :title="messageForm.typeName" :visible.sync="messageDialog" :show-close="false" :close-on-press-escape='false' :close-on-click-modal="false" >
      <div>
        {{messageForm.content}}
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="primary" @click="messageDialog = false">确定</el-button>
      </div>
    </el-dialog>
  </el-menu>
</template>
 
<script>
import mqtt from "mqtt";
import { mapGetters, mapActions } from 'vuex'
import Hamburger from '@/components/Hamburger'
import logoPng from '@/assets/logo.png?imageView2/1/w/80/h/80'
import * as unReadMessage from '@/api/sysmessages'//新物料编码远程搜索
import {parseTime} from '@/utils/index'
import * as basicConfiguration from '@/api/basicConfiguration/basicConfiguration' 
import * as categorys from '@/api/categorys'

export default {
  data: function() {
    return {
      logo: logoPng,
      theme: 0,
      messageList:[],
      socket:'',//websocket
      unReadNum:10,//未读消息总数
      messageDialog:false,//消息弹窗
      messageForm:{},//消息内容
      client:null,
      drawer:false,//抽屉控制
      chatPersonList:[],//联系人列表
      activeTab:{},//当前选中tab
      activeName:'',//当前选中用户，tab组件标识
      recordList:[],//聊天记录列表
      sendMsg:'',//发送消息内容
      searchChat:'',//搜索联系人
      myData:{},//用户信息
    }
  },
  components: {
    Hamburger
  },
  computed: {
    ...mapGetters(['sidebar', 'isIdentityAuth', 'name', 'themeStatus'])
  },
  updated(){
    // 聊天定位到底部
    let ele = document.getElementById('chat_record');
    if(ele){
      ele.scrollTop = ele.scrollHeight;
    }
  },
  watch: {
    theme() {
      this.toggleClass(document.body, 'custom-theme')
      this.logo = logoPng
    },
  },
  created() {
    // this.printTopic()   //获取用户组织信息 topic (记录打印身份信息)
    this.getUnreadList();//获取未读消息列表
    
  },
  mounted() {
    this.theme = Number(this.themeStatus)
    this.toggleClass(document.body, 'custom-theme')
  },
  methods: {
    ...mapActions([
      'signOutOidc',
      'saveTheme'
    ]),
    toggleClass(element, className) {
      if (!element || !className) {
        return
      }
      let classString = element.className
      const nameIndex = classString.indexOf(className)
      if (nameIndex === -1) {
        classString += '' + className
      } else if(this.theme !== 1){
        classString =
          classString.substr(0, nameIndex) +
          classString.substr(nameIndex + className.length)
      }
      element.className = this.theme === 1 ? classString : ''
      this.saveTheme(this.theme)
    },
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      if (this.$store.state.sbxxTimer) {
          clearInterval(this.$store.state.sbxxtimer);
        }
      if (this.isIdentityAuth) {
        this.signOutOidc()
      } else {
        this.$store.dispatch('LogOut').then(() => {
          // alert('退出成功')
          this.$router.push({ path: '/login' })
          // location.reload() // 为了重新实例化vue-router对象 避免bug
          localStorage.removeItem("userCode")
        })
      }
    },
    //个人中心跳转
    handleGoProfile() {
      this.$router.push('/profile')
    },
    handleCommand(name) {
      if(!name) return
      this[name]()
    },
    //消息下拉点击事件
    handleCommandMessage(command){
      switch (command.command) {
        case "a"://消息弹窗
          this.goSysMessages(command.item)
          break;
        case "b":
          this.allRead()
          break;
      }
    },
    // 消息列表点击
    beforeHandleCommand(item,id,i){
      return {
        'command': item,
        'id': id,
        'item':i,
      }
    },
    //消息弹窗
    async goSysMessages(item){
      this.messageDialog = true
      this.messageForm = item
      this.readMessage(item.id)//消息置为已读
    },
    // 消息置为已读
    async readMessage(id){
      var data={
        id:id
      }
      await unReadMessage.read(data).then(() => {})
    },
    //获取未读消息列表
    async getUnreadList(){
      var data={
        status:999,//消息状态 0:未读；1：已读； 999:全部
        page:1,
        limit:999,
        typeId:'SYS_MSG',
      }
      await unReadMessage.getList(data).then((res) => {
        if(res.code == '200'){
          this.messageList = res.data.slice(0,5)
          this.unReadNum = res.unReadCount
        }else{
          this.messageList = []
        }
      }).catch(()=>{
        this.messageList = []
      })
    },
    //全部已读
    async allRead(){
      var data={
        typeId:'SYS_MSG',
      }
      await unReadMessage.allRead(data).then((res) => {
        if(res.code == 200){
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 2000
          })
          this.getUnreadList()
        }else{
          this.$notify({
            title: '失败',
            message: res.message,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 获取用户组织信息（公司、部门等）
    printTopic(){  
      basicConfiguration.getTopic().then((res) => {
        if(res.code=='200'){
          this.$store.state.topicData = res.result
          this.roleName(); //获取用户信息
        }
      }).catch(() => {
        this.$store.state.topicData = []
      })
    },
    // 获取用户名列表
    roleName(){
      categorys.getList_Table({ 
        page: 1,
        limit: 9999,
        TypeId: 'UserMsg',
      }).then(res => {
        if(res.code=='200'){
          var s = res.data.filter(i=>{
            return i.account ==localStorage.getItem("userName")
          })
          this.myData = s[0]
          //过滤当前登录用户
          this.$store.state.roleNames=res.data.filter(i=>{
            return i.account !=localStorage.getItem("userName")
          })
          this.getContactPersonList();//获取联系人列表
        }
      }).catch(()=>{
          this.$store.state.roleNames=[]
      })
    },
    //获取联系人列表
    async getContactPersonList(){
      var data = {
        pageNo:1,
        pageSize:999
      }
      unReadMessage.getUserOnline(data).then(res => {
        var all = this.$store.state.roleNames.concat()
        var online = this.$store.state.roleNames.filter(i=>res.result.data.some(ele=>i.account == ele.user))
        for (let i = 0; i < all.length; i++) {
          for (let j = 0; j < online.length; j++) {
            if(all[i].account == online[j].account){
              all.splice(i,1)
            }
          }
        }

        online.forEach(i=>{
          i.online = true//是否在线
        })
        this.chatPersonList  = online.concat(all)
        // this.initMqtt();// Mqtt初始化
      }).catch(()=>{
        
      })
    },
    
    // 生成GUID随机数
    generateGUID(){
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    //Mqtt初始化
    initMqtt(){
      // const Mqtt_URL = "ws://192.168.110.176:2883/mqtt";
      const Mqtt_URL = "ws://192.168.2.110:2883/mqtt";
      // 生成GUID随机数
      var clientId = (this.generateGUID() + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + this.generateGUID() + this.generateGUID());
      //生成随机 GUID 数
      const options = {
        connectTimeout: 30000,// 超时时间
        // 认证信息
        clientId:clientId,  //Ukey序列号
        username: localStorage.getItem("userName"), //用户名
        password: localStorage.getItem("password"), //密码
        keepalive: 60,// 心跳时间
        clean: true, // 保留会话
        reconnectPeriod:2000,// 重连时间间隔
      }
      try {
        this.$store.state.client = mqtt.connect(Mqtt_URL, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
      // 订阅主题
      var topicList = []
      this.$store.state.roleNames.forEach(i=>{
        var topic='/msg/'+ this.$store.state.topicData.companyList[0].companyId + '/accout/' + i.id
        topicList.push(topic)
      })
      this.$store.state.client.on('connect', () => {
        console.log('Connection succeeded!')//链接成功
        this.$store.state.client.subscribe(topicList, { qos: 0 }, (error, res) => {
          if (error) {
            console.log('订阅失败', error)
            return
          }
          console.log('订阅成功', res)
        })
      })
      
      this.$store.state.client.on('error', error => {
        console.log('Connection failed', error)
      })
      // 其他异常事件
      this.$store.state.client.on('reconnect', () => {
        console.info('正在重连')
      });
      this.$store.state.client.on('disconnect', (error) => {
        console.info('服务器断开:', error)
      });
      this.$store.state.client.on('close', () => {
        this.$store.state.client.end();
      });
      //监听获取信息
      this.getMessage()
    },
    
    //监听获取信息
    getMessage(){
      // 接收消息处理
      this.$store.state.client.on('message', (topic, message) => {
        // console.log('主题为'+topic+ "接收成功",JSON.parse(message))
        var s ={createTime:parseTime(new Date()),content:JSON.parse(message).content,isMy:false,fromName:JSON.parse(message).fromName}
        this.recordList.push(s)
        // 默认当前聊天选中并置顶
        this.chatPersonList.forEach(i=>{
          if(i.id ==JSON.parse(message).fromId){
            this.activeTab = i
          }
        })
        this.toTopTabs(2);//当前聊天置顶
      })
    },
    // 发送消息
    async onSendMsg(){
      // 前端插入聊天记录
      var s ={createTime:parseTime(new Date()),content:this.sendMsg,isMy:true,fromName:this.$store.state.topicData.userName}
      this.recordList.push(s)
      // 前端插入聊天记录
      this.toTopTabs(2);//当前聊天置顶
      // 聊天json封装
      var sendJson = {
        typeName:'用户消息',
        typeId:'USR_MSG',
        fromId:this.myData.id,
        toId:this.activeTab.id,
        fromName:this.myData.name,
        toName:this.activeTab.name,
        fromStatus:0,
        toStatus:0,
        title:'',
        content:this.sendMsg,
      }
      // 发送消息
      var topic='/msg/'+ this.$store.state.topicData.companyList[0].companyId + '/accout/' + this.$store.state.topicData.userId
      this.$store.state.client.publish(topic, JSON.stringify(sendJson),{qos: 0},(err) => {
        if(!err) {
          // console.log('主题为'+topic+ "发布成功")
        }
      })
      this.sendMsg = ''
    },
    // 首次获取聊天
    async getFirstChat(){
      var sdata={
        status:999,
        fromName:this.myData.name,
        toName:this.activeTab.name,
        fromId:this.myData.id,
        toId:this.activeTab.id,
        typeId:'USER_MSG'
      }
      await basicConfiguration.getChatContent(sdata).then((res) => {
        if(res.code == '200'){
          var handle = res.data
          handle.forEach(i=>{
            if(i.fromId == this.myData.id){
              i.isMy = true
            }
          })
          this.recordList = handle
        }else{
          this.recordList = []
        }
      })
    },
    //当前聊天置顶
    toTopTabs(type){
      // type:1搜索,2聊天
      var t = ''
      if(type == 1){
        t = this.searchChat
      }else{
        t = this.activeTab.account
      }
      var s={}
      for (let i = 0; i < this.chatPersonList.length; i++) {
        if(this.chatPersonList[i].account == t){
          s = this.chatPersonList[i]
          this.chatPersonList.splice(i,1)
          this.chatPersonList.unshift(s)
          this.activeName = s.name
          this.activeTab = s
          if(type == 1){//搜索请求接口获取聊天历史
            this.getFirstChat()
          }
          return
        }
      }
    },
    
    // 展开聊天
    openChat(){
      this.activeTab =this.chatPersonList[0]
      this.activeName = this.chatPersonList[0].name
      this.drawer = !this.drawer
      if(this.drawer){
        this.getFirstChat()
      }
    },
    //切换联系人
    async changePerson(v){
      //获取当前选中联系人数据
      this.chatPersonList.forEach(i=>{
        if(i.account == v.label){
          this.activeTab = i
        }
      })
      var req = false //是否需要请求后台已读
      // 前端置为已读状态
      this.chatPersonList.forEach(i=>{
        if(i.name == v.label){
          if(i.messageYn){
            req = true
            i.messageYn = false;//置为已读
          }
        }
      })
      
      // 请求聊天记录
      var sdata={
        status:999,
        fromName:this.myData.name,
        toName:this.activeTab.name,
        fromId:this.myData.id,
        toId:this.activeTab.id,
        typeId:'USER_MSG'
      }
      await basicConfiguration.getChatContent(sdata).then((res) => {
        if(res.code == '200'){
          var handle = res.data
          handle.forEach(i=>{
            if(i.fromId == this.myData.id){
              i.isMy = true
            }
          })
          this.recordList = handle
        }else{
          this.recordList = []
        }
      })

      if(req){
        // 通过接口置为已读
        var tdata={
          fromName:v.label,
          toName:localStorage.getItem("userName"),
          typeId:'chat'
        }
        await basicConfiguration.updateRead(tdata).then(() => {})
      }
      
    },
    //搜索筛选
    querySearchChat(queryString, cb) {
      var restaurants = this.chatPersonList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 搜索筛选
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.account.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    // 搜索
    searchChatPerson(){
      if(this.searchChat){
        this.toTopTabs(1)
      }else{
        return;
      }
    },
    
  },
  // destroyed() {
  //   //监听销毁MQTT
  //   this.$store.state.client.end();
  // },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .personal_icon{
    margin-right: 5px;
    font-size: 16px;
  }
  .swith_css{
    margin-left: 5px;
  }
  .badge{
    ::v-deep .el-badge__content.is-fixed{
      top: 12px;
    }
  }
  .item_badge{
    ::v-deep .el-badge__content.is-fixed{
      top: 15px;
      left: -20px;
    }
  }
  .message_title{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #515a6e;
    margin: 5px 0;
    max-width: 260px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .message_time{
    margin:0 0 10px 0;
    font-size: 12px;
    line-height: 15px;
    color: #808695;
  }
  .tips_message_dropdowm{
    padding: 0;
    .el-dropdown-menu__item{
      max-width: 300px;
      border-bottom: 1px solid #e8eaec;
    }
  }
  
</style>
<style rel="stylesheet/scss" lang="scss" >
.pop_notify{
  .el-notification__group{
    width: 86%;
    margin-left: 5px;
    .el-notification__title{
      // text-align: center;
      color: #0a76a4;
    }
    .el-notification__content{
      margin-top: 10px;
      text-indent: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; // 作为弹性伸缩盒子模型显示。
      -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式--从上到下垂直排列
      -webkit-line-clamp: 5; // 显示的行
      p{
        word-wrap:break-word;
        word-break:normal; 
      }
    }
  }
}
</style>
<style scoped>
.navbar .logo,.navbar .logo .user-avatar{
  padding-left: 5px;
  width: auto;
}
</style>