import Vue from 'vue'
import layer from 'vue-layer'
import 'normalize.css/normalize.css'// A modern alternative to CSS resets
import 'jquery'//引入jquery

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/custom-theme/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import VueContextMenu from 'vue-contextmenu'
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式



import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'
import momentDataFormat from 'moment';//时间格式转换
Vue.prototype.$momentDataFormat = momentDataFormat;

import '@/permission' // permission control

// import '@/assets/public/css/comIconfont/iconfont.css'
// import '@/assets/public/css/comIconfont/iconfont.js'

//工作流使用的图标
import '@/assets/public/css/workflowicon/iconfont.css'
import '@/assets/public/css/workflowicon/iconfont.js'
//引入阿里矢量图
import '@/assets/iconfont/iconfont.css'


import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'
import '../public/ueditor/formdesign/leipi.formdesign.v4.js'


// 请假条表单和详情
import FrmLeaveReqAdd from '@/views/forms/userDefine/frmLeaveReq/add'
import FrmLeaveReqUpdate from '@/views/forms/userDefine/frmLeaveReq/update'
import FrmLeaveReqDetail from '@/views/forms/userDefine/frmLeaveReq/detail'

//引入主css
import '@/assets/css/wMain.css'
//引入数据分析css
import '@/assets/css/analysis.css'

// 引入pltable
// import plTable from 'pl-table'
// import 'pl-table/themes/index.css' // 引入样式（必须引入)，vuecli3.0不需要配置，cli2.0请查看webpack是否配置了url-loader对woff，ttf文件的引用,不配置会报错哦
// import 'pl-table/themes/plTableStyle.css' // 默认表格样式很丑 引入这个样式就可以好看啦（如果你不喜欢这个样式，就不要引入，不引入就跟ele表格样式一样）


// 引入get、post方法
import {autoGetMeth, autoPostMeth} from '@/utils/request.js'
Vue.prototype.$autGet = autoGetMeth;
Vue.prototype.$autPost = autoPostMeth;
// 引入get、post方法

//引入全局的过滤器
 import filters from '@/assets/js/filter.js'
 Vue.filter('notnum',filters)

//引入全局的 云完成小数判断
import * as func from '@/assets/js/fun.js'
Vue.prototype.$func = func

// 引入ERP接口文件
import wRequest from '@/utils/wRequest.js';


//引入全局的form表单column
import secondFormColumn from '@/assets/js/secondFormColumn.js'
//引入全局的search表单column
import baseSearch from '@/assets/js/baseSearch.js'
//引入全局的table表格表头column
import tableHeaderList from '@/assets/js/tableHeaderList.js'


// 2022-7-5 15:53:46合并最新版流程和表单设计
import vform from '@/lib/vform/VFormDesigner.umd.min.js'
import '@/lib/vform/VFormDesigner.css'
Vue.use(vform)

// 2022-10-13 15:03:06 引入全局方法
import basicFun from '@/assets/js/baseFunction.js'
Vue.prototype.$basicFun = basicFun


ElementUI.Dialog.props.closeOnClickModal.default = false; // 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.modalAppendToBody.default = false; // 修改 切页面 遮罩不去掉
// ElementUI.Div.props.elementLoadingText ="拼命加载中"; // 修改 

// 2023-1-16 15:42:04 引入数字滚动
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 2023-2-23 08:15:21 引入ewms组件
import myUI from 'ewms-plug-in';
import "ewms-plug-in/dist/ewms-plug-in.css"; 
Vue.use(myUI);

Vue.use(UmyUi);
Vue.use(secondFormColumn);
Vue.use(tableHeaderList);
Vue.use(baseSearch);
Vue.use(wRequest)
// Vue.use(plTable);
Vue.use(ElementUI, { locale })
Vue.use(VueContextMenu)

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue, {
  msgtime: 3
})
Vue.component('FrmLeaveReqAdd', FrmLeaveReqAdd)
Vue.component('FrmLeaveReqUpdate', FrmLeaveReqUpdate)
Vue.component('FrmLeaveReqDetail', FrmLeaveReqDetail)
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
