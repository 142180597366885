<template>
  <div class="tags-view-container">
    <scroll-pane class='tags-view-wrapper' ref='scrollPane'>
      <!-- <router-link ref='tag' class="tags-view-item" :class="isActive(tag)?'active':''" v-for="tag in Array.from(visitedViews)" :to="tag" :key="tag.fullPath" @contextmenu.prevent.native="openMenu(tag,$event)"> -->
      <!-- 测试绑定fullPath跳转 -->
      <router-link ref='tag' class="tags-view-item" :class="isActive(tag)?'active':''" v-for="tag in Array.from(visitedViews)" :to="tag.fullPath" :key="tag.fullPath" @contextmenu.prevent.native="openMenu(tag,$event)">
        {{tag.title}}
        <span class='el-icon-close' @click.prevent.stop='closeSelectedTag(tag)'></span>
      </router-link>
    </scroll-pane>
    <ul class='contextmenu' v-show="visible" :style="{left:left+'px',top:top+'px'}">
      <li @click="closeSelectedTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags">全部关闭</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/components/ScrollPane'
import { mapGetters } from 'vuex'
export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    }
  },
  computed: {
    ...mapGetters(['iframeViews']),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    }
  },
  watch: {
    $route() {
      this.addViewTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.addViewTags()
  },
  methods: {
    generateRoute() {
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      //处理地址，过滤掉地址参数，使选中跟路由一致
      var handlePath = this.$route.fullPath.replace(/(\?|#)[^'"]*/, '')
      return route.fullPath === handlePath
    },
    addViewTags() {
      const route = this.generateRoute()
      if (!route) {
        return false
      }
      //限制菜单重名后出现多个tags
      for (let i = 0; i < this.visitedViews.length; i++) {
        if(this.visitedViews[i].fullPath === route.fullPath){
          return false
        }
      }
      this.$store.dispatch('addVisitedViews', route)
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.fullPath === this.$route.fullPath) {
            this.$refs.scrollPane.moveToTarget(tag.$el)
            break
          }
        }
      })
    },
    closeSelectedTag(view) {
      var beforeView = []
      for (let i = 0; i < this.$store.state.tagsView.visitedViews.length; i++) {
        if(this.$store.state.tagsView.visitedViews[i] === view){
          beforeView = this.$store.state.tagsView.visitedViews[i+1]
        }
      }
      this.$store.state.isLeaveTags = true;
      this.$store.dispatch('delVisitedViews', view).then(views => {
        const latestView = views.slice(-1)[0]
        if (this.isActive(view)) {
          if (beforeView) {// 跳转到下一个标签
            this.$router.push(beforeView)
          } else if(latestView){//如果没有下一个标签但有前一个标签，跳转到前一个标签
            this.$router.push(latestView)
          }else{
            this.$router.push('/')
          }
          /* // 原代码，跳转到最后一个标签
          const latestView = views.slice(-1)[0]
          if (latestView) {
            this.$router.push(latestView)
          } else {
            this.$router.push('/')
          } */
          /* // 使用路由历史返回上一个，但是会出现返回不存在的标签（新增这个标签并跳转）
          if (views.length>1) {
            this.$router.go(-1)
          } else {
            this.$router.push('/')
          } */
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag)
      this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags() {
      this.$store.dispatch('delAllViews')
      this.$router.push('/')
    },
    openMenu(tag, e) {
      this.visible = true
      this.selectedTag = tag
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      this.left = e.clientX - offsetLeft + 15 // 15: margin right
      this.top = e.clientY
    },
    closeMenu() {
      this.visible = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.custom-theme{
  .tags-view-container {
    .tags-view-wrapper {
      .tags-view-item {
        &.active {
          background: #1a96d4;
          color: #fff;
          // background-color: #efefef;
          border-color: #dcdfe6;
          border-radius: 5px;
          // &::before {
          //   background: #1a96d4;
          // }
        }
      }
    }
  }

}
.tags-view-container {
    margin-top: 45px;
    .tags-view-wrapper {
        background: #fff;
        height: 34px;
        border-bottom: 1px solid #d8dce5;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
        .tags-view-item {
          border-radius: 5px;
          display: inline-block;
          position: relative;
          height: 26px;
          line-height: 28px;
          // border: 1px solid #d8dce5;
          color: #495060;
          // background: #fff;
          background-color: #efefef;
          padding: 0 8px;
          font-size: 12px;
          margin-left: 5px;
          margin-top: 4px;
          &.active {
            color: #fff;
            background-color: #1a96d4;
            border-color: #1a96d4;
            &::before {
                content: '';
                background: #fff;
                // display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: relative;
                // margin-right: 2px;
            }
          }
        }
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 10000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;
            &:hover {
                background: #eee;
            }
        }
    }
}
</style>

<style rel="stylesheet/scss" lang="scss">
.tags-view-wrapper {
    .tags-view-item {
        .el-icon-close {
            width: 16px;
            height: 16px;
            font-size: 16px;
            vertical-align: inherit;
            border-radius: 50%;
            text-align: center;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transform-origin: 100% 50%;
            &:before {
              transform: scale(0.6);
              display: inline-block;
              vertical-align: -1px;
            }
            &:hover {
              background-color: #b4bccc;
              color: #fff;
            }
        }
    }
}

.tags-view-wrapper {
  .tags-view-item {
    &.active {
      .el-icon-close {
        &:hover {
          background-color: #fff;
          color: #b4bccc;
        }
      }
    }
  }
}

</style>
