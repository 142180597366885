import request from '@/utils/request'
// 修改物料管理中的分配责任人
export function AddOrUpdateGoodsResponseRelation(data) {
  return request({
    url: '/WmsGoodsResponseRelation/AddOrUpdateGoodsResponseRelation',
    method: 'post',
    data
  })
}
//物料管理 修改ERP仓库
export function setSqPickArea(data) {                
  return request({
    url: '/WmsGoodsErpStockRelation/AddOrUpdateGoodsErpStockRelation',
    method: 'post',
    data
  }) 
}
//物料管理 修改安全库存
export function upStock(data) {                
  return request({
    url: '/WmsGoodsMsts/UpdateSafeInvenNum',
    method: 'post',
    data
  }) 
}
//条码管理 批量添加 虚列号
export function addsBarcode(data) {                
  return request({
    url: '/WmsBarCodeMsts/AddList',
    method: 'post',
    data
  }) 
}
//物料管理 修改是否备货
export function upBeihuo(data) {                
  return request({
    url: '/WmsGoodsMsts/SetNoPrepare',
    method: 'post',
    data
  }) 
}
// 备货报表 获取主表数据
export function getTableData(params) {                
  return request({
    url: '/WmsBigTv/GetTempDataReport',
    method: 'get',
    params
  }) 
}
//备货报表 获取订单明细
export function getPickApply(params) {                
  return request({
    url: '/WmsBigTv/GetBeihuoDetailList',
    method: 'get',
    params
  }) 
}
//条码管理 点击供应商详情 获取  条码的所有供应商
export function getSupplierName(params) {                
  return request({
    url: '/WmsBarcodeSupplierRelationMsts/Load',
    method: 'get',
    params
  }) 
}
//删除条码供应商
export function delSupplierName(data) {                
  return request({
    url: '/WmsBarcodeSupplierRelationMsts/Delete',
    method: 'post',
    data
  }) 
}
//条码管理 添加供应商
export function addSupplier(data) {                
  return request({
    url: '/WmsBarcodeSupplierRelationMsts/AddRelation',
    method: 'post',
    data
  }) 
}
//获取打印模板菜单
export function getTemplateData(params) {                
  return request({
    url: '/WmsBaseData/GetPrintMenu',
    method: 'get',
    params
  }) 
}
//获取打印模板菜单 中每个菜单的模板明细(只包括一部分)
export function getTemplateDataDetail(params) {                
  return request({
    url: '/WmsBaseData/GetPrintLableList',
    method: 'get',
    params
  }) 
}
//获取全部打印模板菜单 中每个菜单的模板明细
export function getAllTemplateDataDetail(params) {                
  return request({
    url: '/WmsBaseData/GetPrintLableList',
    method: 'get',
    params
  }) 
}
//获取物料打印模板
export function getAllTemplateDataDetailGoods(params) {                
  return request({
    url: '/WmsBaseData/GetPrintLableList_Goods',
    method: 'get',
    params
  }) 
}

//批量获取物料的下能打印的模板
export function getGoodTemplate(data) {                
  return request({
    url: '/WmsPrintModelRelation/GetRelationByGoods',
    method: 'post',
    data
  }) 
}
//批量修改打印模板
export function upGoodTemplate(data) {                
  return request({
    url: '/WmsPrintModelRelation/UpdateRelation',
    method: 'post',
    data
  }) 
}
//获取打印角色
export function getTopic(params) {                
  return request({
    url: '/Check/GetUserInfor',
    method: 'get',
    params
  }) 
}
//采购入库申请补打条码
export function getBdtm(data) {
  return request({
    url: '/WmsBaseData/GetRePrintInformation',
    method: 'post',
    data
  })
}



//获取聊天信息
export function getChatContent(params) {                
  return request({
    url: '/SysMessages/Load',
    method: 'get',
    params
  }) 
}
//置为已读
export function updateRead(data) {                
  return request({
    url: '/SysMessages/ReadChet',
    method: 'post',
    data
  }) 
}
//采购入库通知单 Net 打印单据
export function cgrktzdNetPrint(data) {                
  return request({
    url: '/WmsPurseArrivalIn/GetPrintPurchaseArrivalList',
    method: 'post',
    data
  }) 
}

//采购入库申请 Net 打印任务单据
export function cgrkNetPrint(data) {                
  return request({
    url: '/WmsPurseArrivalIn/GetPrintTaskFromOrderList',
    method: 'post',
    data
  }) 
}
//采购入库任务 Net 打印任务单据
export function cgrkrwNetPrint(data) {                
  return request({
    url: '/WmsPurseArrivalIn/GetPrintTaskInOrderList',
    method: 'post',
    data
  }) 
}
//采购退货通知单 Net 打印单据
export function cgthtzdNetPrint(data) {                
  return request({
    url: '/OutReturnGoods/GetPrintOutboundArrivalList',
    method: 'post',
    data
  }) 
}

//采购退货申请 Net 打印任务单据
export function cgthNetPrint(data) {                
  return request({
    url: '/OutReturnGoods/GetPrintOutboundOrderList',
    method: 'post',
    data
  }) 
}
//采购退货任务 Net 打印任务单据
export function cgthrwNetPrint(data) {                
  return request({
    url: '/OutReturnGoods/GetPrintOutboundTaskOrderList',
    method: 'post',
    data
  }) 
}
//生产领料通知单 Net 打印单据
export function sclltzdNetPrint(data) {                
  return request({
    url: '/WmsOutMaterial/GetPrintMaterialOutOrderList',
    method: 'post',
    data
  }) 
}

//生产领料申请 Net 打印任务单据
export function scllsqNetPrint(data) {                
  return request({
    url: '/WmsOutMaterial/GetPrintOutboundTaskFromOrderList',
    method: 'post',
    data
  }) 
}
//生产领料任务 Net 打印任务单据
export function scllrwNetPrint(data) {                
  return request({
    url: '/WmsOutMaterial/GetPrintOutboundTaskOrderList',
    method: 'post',
    data
  }) 
}
//生产退料通知单 Net 打印单据
export function sctltzdNetPrint(data) {                
  return request({
    url: '/WmsReMaterial/GetPrintReMaterialInOrderList',
    method: 'post',
    data
  }) 
}

//生产退料申请 Net 打印任务单据
export function sctlsqNetPrint(data) {                
  return request({
    url: '/WmsReMaterial/GetPrintReMaterialInOrderList',
    method: 'post',
    data
  }) 
}
//生产退料任务 Net 打印任务单据
export function sctlrwNetPrint(data) {                
  return request({
    url: '/WmsReMaterial/GetPrintReMaterialTaskInOrderList',
    method: 'post',
    data
  }) 
}
//成品入库通知单 Net 打印单据
export function cprktzdNetPrint(data) {                
  return request({
    url: '/WmsInProduct/GetPrintProductInOrderList',
    method: 'post',
    data
  }) 
}

//成品入库申请 Net 打印任务单据
export function cprksqNetPrint(data) {                
  return request({
    url: '/WmsInProduct/GetPrintProductInOrderList',
    method: 'post',
    data
  }) 
}
//成品入库任务 Net 打印任务单据
export function cprkrwNetPrint(data) {                
  return request({
    url: '/WmsInProduct/GetPrintTaskInOrderList',
    method: 'post',
    data
  }) 
}
//销售出库通知单 Net 打印单据
export function xscktzdNetPrint(data) {                
  return request({
    url: '/WmsOutProduct/GetPrintOutboundOrderList',
    method: 'post',
    data
  }) 
}

//销售出库申请 Net 打印任务单据
export function xscksqNetPrint(data) {                
  return request({
    url: '/WmsOutProduct/GetPrintOutboundOrderList',
    method: 'post',
    data
  }) 
}
//销售出库任务 Net 打印任务单据
export function xsckrwNetPrint(data) {                
  return request({
    url: '/WmsOutProduct/GetPrintOutboundTaskOrderList',
    method: 'post',
    data
  }) 
}
//销售退货通知单 Net 打印单据
export function xsthtzdNetPrint(data) {                
  return request({
    url: '/WmsReProduct/GetPrintReProductInArrivalList',
    method: 'post',
    data
  }) 
}

//销售退货申请 Net 打印任务单据
export function xsthsqNetPrint(data) {                
  return request({
    url: '/WmsReProduct/GetPrintReProductInOrderList',
    method: 'post',
    data
  }) 
}
//销售退货任务 Net 打印任务单据
export function xsthrwNetPrint(data) {                
  return request({
    url: '/WmsReProduct/GetPrintReProductTaskInOrderList',
    method: 'post',
    data
  }) 
}



//支给品入库申请 Net 打印任务单据
export function zgprkNetPrint(data) {                
  return request({
    url: '/WmsStockInsideManage/GetPrintTaskFromOrderList',
    method: 'post',
    data
  }) 
}
//获取模板对应关系主表信息
export function getModulesPDA(params) {                
  return request({
    url: '/Check/GetModules_PDA',
    method: 'get',
    params
  }) 
}
// 获取模板对应关系 明细表信息     
export function getModulesDetail(params) {                
  return request({
    url: '/WmsPdaModelTypeRelation/GetModelTypeList',
    method: 'get',
    params
  }) 
}
// 获取模板对应关系 添加 模板
export function addModel(data) {                
  return request({
    url: '/WmsPdaModelTypeRelation/AddTypeList',
    method: 'post',
    data
  }) 
}
// 获取模板对应关系 删除模板
export function delModel(data) {                
  return request({
    url: '/WmsPdaModelTypeRelation/DelTypeList',
    method: 'post',
    data
  }) 
}
//获取全局的字典
export function getCategorys(params) {                
  return request({
    url: '/Categorys/SearchAllTypeList',
    method: 'get',
    params
  }) 
}


// 获取第三方关联信息
export function getThirdPartyMes(data) {                
  return request({
    url: '/WmsUserThirdRelationTbl/LoadThirdRelation',
    method: 'post',
    data
  }) 
}// 添加第三方关联信息
export function addThirdPartyMes(data) {                
  return request({
    url: '/WmsUserThirdRelationTbl/AddThirdRelation',
    method: 'post',
    data
  }) 
}// 删除第三方关联信息
export function delThirdPartyMes(data) {                
  return request({
    url: '/WmsUserThirdRelationTbl/DeleteThirdRelation',
    method: 'post',
    data
  }) 
}
// 物料管理  是否需要质检

export function UpdateIQCCheckYn(data) {                
  return request({
    url: '/WmsGoodsMsts/UpdateIQCCheckYn',
    method: 'post',
    data
  }) 
}


// 通过订单明细 获取  打印模板

export function getPringModel(data) {                
  return request({
    url: '/WmsInboundOrderDtbls/GetPrintInfo',
    method: 'post',
    data
  }) 
}

// 采购订单明细  获取 打印数据

export function cgddGetPringModel(data) {                
  return request({
    url: '/WmsPurchaseOrderDtbls/LoadPrintInfo',
    method: 'post',
    data
  }) 
}
// 代码生成同步实体类
export function synClass(data) {                
  return request({
    url: '/BuilderTableColumns/SyncBuilderTable',
    method: 'post',
    data
  }) 
}

// 代码生成同步属性
export function synattr(data) {                
  return request({
    url: '/BuilderTableColumns/SyncBuilderTableColumn',
    method: 'post',
    data
  }) 
}
// 获取app标题
export function getTitle(params) {                
  return request({
    url: '/HdybPdaTitleInforMst/GetTitle',
    method: 'get',
    params
  }) 
}
//需要改app标题
export function subtitle(data) {                
  return request({
    url: '/HdybPdaTitleInforMst/SetTitle',
    method: 'post',
    data
  }) 
}

