var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    {
      staticStyle: { "border-right": "1px solid #f5f5f5" },
      attrs: { wrapClass: "scrollbar-wrapper" },
    },
    [
      _c(
        "el-menu",
        {
          attrs: {
            mode: "vertical",
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            router: "",
            "background-color": _vm.themeStatus ? "#304156" : "",
            "text-color": _vm.themeStatus ? "#bfcbd9" : "",
            "active-text-color": _vm.themeStatus ? "#1a96d4" : "#1a96d4",
          },
        },
        _vm._l(_vm.routes, function (route) {
          return _c("sidebar-item", {
            key: route.name,
            attrs: { item: route, "base-path": route.path },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }