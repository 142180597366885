/* * 
 * @comment: 通用表格表头
 * @author: Long
 * @version: 1.0
 * @updateDate:2022-6-13
 * @comment: 通用的表头数据，定义如下：
 * */
 export default {
  install(Vue) {
    // 基础配置 - 项目管理
    Vue.prototype.$jcpzXmglTableHeader = [//表头数据
      {comment:'项目编码',key:'projectCode',columnWidth:80,},
      {comment:'项目名称',key:'projectName',columnWidth:160,},
      {comment:'上级项目',key:'superiorProject',columnWidth:180,},
      {comment:'公司名称',key:'companyName',columnWidth:160,},
      {comment:'详细地址',key:'companyAddress',columnWidth:160,},
      {comment:'联系人',key:'contact',columnWidth:160,},
      {comment:'联系方式',key:'contactType',columnWidth:160,},
      {comment:'申请时间',key:'applyTime',columnWidth:120,},
      {comment:'充值打印次数',key:'rechargePrintTimes',columnWidth:120,},
      {comment:'水司类型',key:'waterSupplyType',columnWidth:120,},
    ]
    // 基础资料 - 数据导入
    Vue.prototype.$jczlSjdrTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:80,},
      {comment:'状态',key:'state',columnWidth:160,},
      {comment:'任务名',key:'taskName',columnWidth:180,},
      {comment:'导入类型',key:'importType',columnWidth:160,},
      {comment:'文件名',key:'fileName',columnWidth:120,},
      {comment:'成功率',key:'successRate',columnWidth:180,},
      {comment:'创建时间',key:'creatTime',columnWidth:250,},
      {comment:'结束时间',key:'endTime',columnWidth:120,},
      {comment:'文件大小(KB)',key:'fileSize',columnWidth:120,},
      {comment:'项目机构',key:'projectOrganization',columnWidth:120,},
      {comment:'用户编号',key:'userNumber',columnWidth:120,},
    ],
    // 基础资料 - 区域管理
    Vue.prototype.$jczlQyglTableHeader = [//表头数据
      {comment:'区域ID',key:'areaId',columnWidth:80,},
      {comment:'区域名称',key:'areaName',columnWidth:160,},
      {comment:'地理位置',key:'geographicalPosition',columnWidth:180,},
      {comment:'详细地址',key:'detailedAddress',columnWidth:160,},
      {comment:'是否挂载设备',key:'isMountDevice',columnWidth:160,},
      {comment:'是否挂载地图',key:'isMountMap',columnWidth:160,},
      {comment:'表挂载数量',key:'surfaceMountNum',columnWidth:160,},
      {comment:'父节点',key:'fatherNode',columnWidth:120,},
      {comment:'项目',key:'project',columnWidth:120,},
      {comment:'项目编号',key:'projectCode',columnWidth:120,},
      {comment:'区域负责人编号',key:'areaPersonNum',columnWidth:160,},
      {comment:'权限用户',key:'jurisdictionUser',columnWidth:160,},
    ]
    // 基础资料 - 集中器管理
    Vue.prototype.$jczlJzqglTableHeader = [//表头数据
      {comment:'集中器名称',key:'concentratorName',columnWidth:180,},
      {comment:'集中器地址',key:'concentratorAddress',columnWidth:160,},
      {comment:'当前状态',key:'nowState',columnWidth:180,},
      {comment:'最后上线时间',key:'lastOnlineTime',columnWidth:180,},
      {comment:'所属区域',key:'belongsArea',columnWidth:120,},
      {comment:'安装地址',key:'installAddress',columnWidth:180,},
      {comment:'通讯规约',key:'communicationProtocol',columnWidth:250,},
      {comment:'方案所属平台',key:'programmePlatform',columnWidth:180,},
      {comment:'项目',key:'project',columnWidth:120,},
    ]

    // 基础资料 - 户表管理 - 住户管理
    Vue.prototype.$jczlHbglTableHeader = [//表头数据
      {key:'loginAccount',comment:'登录账号',columnWidth:80,},
      {key:'userNumber',comment:'用户编号',columnWidth:80,},
      {key:'userName',comment:'用户姓名',columnWidth:80,},
      {key:'mobilePhoneNum',comment:'手机号码',columnWidth:80,},
      {key:'IDCard',comment:'身份证号',columnWidth:80,},
      {key:'balance',comment:'余额',columnWidth:80,},
      {key:'rechargeTime',comment:'充值时间',columnWidth:80,},
      {key:'waterDepartmentCode',comment:'水司编码',columnWidth:80,},
    ]

    // 基础资料 - 户表管理 - 仪表管理
    Vue.prototype.$jczlHbglTableHeader = [//表头数据
      {comment:'用户id',key:'userId',columnWidth:80,},
      {comment:'表id',key:'surfaceId',columnWidth:160,},
      {comment:'安装地址',key:'installAddress',columnWidth:180,},
      {comment:'表名称',key:'surfaceName',columnWidth:120,},
      {comment:'表编号',key:'surfaceNum',columnWidth:120,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:120,},
      {comment:'IMEI',key:'imei',columnWidth:120,},
      {comment:'类型规格',key:'typeSpecification',columnWidth:120,},
      {comment:'通讯序号',key:'communicationNum',columnWidth:120,},
      {comment:'是否报装',key:'isPacking',columnWidth:120,},
      {comment:'报装日期',key:'packingTime',columnWidth:120,},
      {comment:'当前读数',key:'currentReading',columnWidth:120,},
      {comment:'用量',key:'dosage',columnWidth:120,},
      {comment:'表类型',key:'surfaceType',columnWidth:120,},
      {comment:'开关状态',key:'switchState',columnWidth:120,},
      {comment:'附加费类型',key:'surchargeType',columnWidth:120,},
      {comment:'收费类型',key:'chargeType',columnWidth:120,},
      {comment:'用量计算方式',key:'consumptionCalculationMethod',columnWidth:120,},
      {comment:'倍率',key:'magnification',columnWidth:120,},
      {comment:'用户告警',key:'userWarning',columnWidth:120,},
    ]

    // 基础资料 - 类型管理
    Vue.prototype.$jczlLxglTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:80,},
      {comment:'类型名称',key:'typeName',columnWidth:160,isClick:true,},
      {comment:'生产厂家',key:'manufacturer',columnWidth:180,},
      {comment:'规格',key:'specifications',columnWidth:180,},
      {comment:'小时流量',key:'hourFlow',columnWidth:120,},
      {comment:'厂家地址',key:'manufacturerAddress',columnWidth:180,},
      {comment:'数显位数',key:'digitalDisplayDigit',columnWidth:250,},
      {comment:'显示序号',key:'displayNum',columnWidth:180,},
      {comment:'显示配置',key:'displayConfigure',columnWidth:120,},
      {comment:'备注',key:'remark',columnWidth:120,},
    ]

    // 基础资料 - NB-IOT注册表
    Vue.prototype.$jczlNbiotzcbTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:80,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'抄表时间',key:'meterReadingTime',columnWidth:180,},
      {comment:'最后更新读数',key:'lastUpdateReading',columnWidth:160,},
      {comment:'阀门状态',key:'valveState',columnWidth:160,},
      {comment:'设备类型',key:'equipmentType',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'上报周期',key:'reportingCycle',columnWidth:160,},
      {comment:'信号强度',key:'signalIntensity',columnWidth:160,},
      {comment:'电池状态',key:'batteryState',columnWidth:160,},
      {comment:'通讯状态',key:'communicationState',columnWidth:160,},
      {comment:'设备使用状态',key:'equipmentUseState',columnWidth:160,},
      {comment:'销售订单号',key:'salesOrderNumber',columnWidth:160,},
      {comment:'订单号',key:'orderNumber',columnWidth:160,},
      {comment:'操作员',key:'operator',columnWidth:160,},
      {comment:'数据修正',key:'dataCorrection',columnWidth:160,},
      {comment:'订单修改时间',key:'orderModifyTime',columnWidth:160,},
      {comment:'采集时间',key:'collectionTime',columnWidth:160,},
      {comment:'版本号',key:'versionNo',columnWidth:160,},
      {comment:'倍率',key:'magnification',columnWidth:160,},
      {comment:'SNR',key:'snr',columnWidth:160,},
      {comment:'ICCID',key:'iccId',columnWidth:160,},
      {comment:'卡类型',key:'cardType',columnWidth:160,},
      {comment:'数据来源',key:'dataSource',columnWidth:160,},
      {comment:'RSRP',key:'rsrp',columnWidth:160,},
      {comment:'PIC',key:'pic',columnWidth:160,},
      {comment:'CELLID',key:'cellid',columnWidth:160,},
      {comment:'阀控支持',key:'valveControlSupport',columnWidth:160,},
      {comment:'创建时间',key:'creatTime',columnWidth:160,},
      {comment:'绑定状态',key:'bindState',columnWidth:120,},
      {comment:'项目机构',key:'projectOrganization',columnWidth:120,},
      {comment:'电压(V)',key:'voltage',columnWidth:120,},
      {comment:'温度',key:'temperature',columnWidth:160,},
      {comment:'压力值',key:'pressureValue',columnWidth:160,},
      {comment:'硬件版本',key:'hardwareVersion',columnWidth:120,},
      {comment:'覆盖等级',key:'coverageLevel',columnWidth:160,},
      {comment:'采集频率',key:'acquisitionFrequency',columnWidth:160,},
      {comment:'IMSI',key:'imsi',columnWidth:120,},
      {comment:'加密',key:'encryption',columnWidth:160,},
      {comment:'灯状态',key:'lampState',columnWidth:160,},
      {comment:'防磁状态',key:'antimagneticState',columnWidth:160,},
      {comment:'无流量告警时间',key:'noFlowAlarmTime',columnWidth:120,},
      {comment:'可用流量',key:'availableFlow',columnWidth:160,},
    ]

    // 基础资料 - 批量处理设备
    Vue.prototype.$jczlPlclsbTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:80,},
      {comment:'用户编号',key:'userNumber',columnWidth:160,},
      {comment:'用户名',key:'userName',columnWidth:180,},
      {comment:'表编号',key:'surfaceNum',columnWidth:160,},
      {comment:'表类型',key:'surfaceType',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'当前读数',key:'currentReading',columnWidth:160,},
      {comment:'上次读数',key:'lastReading',columnWidth:120,},
      {comment:'用量',key:'dosage',columnWidth:120,},
      {comment:'是否告警',key:'isWarning',columnWidth:120,},
      {comment:'告警类型',key:'warningType',columnWidth:160,},
      {comment:'告警上限',key:'warningTopLimit',columnWidth:160,},
      {comment:'告警下限',key:'warningLowerLimit',columnWidth:160,},
      {comment:'附加费方案名',key:'surchargeSchemeName',columnWidth:180,},
      {comment:'收费类型',key:'chargeType',columnWidth:160,},
      {comment:'收费方式',key:'chargingMethod',columnWidth:160,},
      {comment:'用量计算方式',key:'consumptionCalculationMethod',columnWidth:160,},
      {comment:'倍率',key:'magnification',columnWidth:160,},
      {comment:'设备最大值',key:'equipmentMaximum',columnWidth:120,},
      {comment:'阀控支持',key:'valveControlSupport',columnWidth:120,},
      {comment:'阀控展示',key:'valveControlShow',columnWidth:120,},
      {comment:'类型规格',key:'typeSpecification',columnWidth:160,},
      {comment:'是否报装',key:'isPacking',columnWidth:160,},
      {comment:'报装日期',key:'packingTime',columnWidth:160,},
    ]

    // 基础资料 - NB-IOT电表
    Vue.prototype.$jczlNbiotdbTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:80,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'抄表时间',key:'meterReadingTime',columnWidth:180,},
      {comment:'最后更新读数',key:'lastUpdateReading',columnWidth:160,},
      {comment:'阀门状态',key:'valveState',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'频率',key:'frequency',columnWidth:160,},
      {comment:'正向电量',key:'forwardPower',columnWidth:120,},
      {comment:'反向电量',key:'reversePower',columnWidth:120,},
      {comment:'A相电流',key:'AElectricCurrent',columnWidth:120,},
      {comment:'B相电流',key:'BElectricCurrent',columnWidth:160,},
      {comment:'C相电流',key:'CElectricCurrent',columnWidth:160,},
      {comment:'A相电压',key:'AVoltage',columnWidth:120,},
      {comment:'B相电压',key:'BVoltage',columnWidth:160,},
      {comment:'C相电压',key:'CVoltage',columnWidth:160,},
      {comment:'A相有功功率',key:'AActivePower',columnWidth:120,},
      {comment:'B相有功功率',key:'BActivePower',columnWidth:160,},
      {comment:'C相有功功率',key:'CActivePower',columnWidth:160,},
      {comment:'总有功功率',key:'totalActivePower',columnWidth:160,},
      {comment:'A相无功功率',key:'AReactivePower',columnWidth:120,},
      {comment:'B相无功功率',key:'BReactivePower',columnWidth:160,},
      {comment:'C相无功功率',key:'CReactivePower',columnWidth:160,},
      {comment:'总无功功率',key:'totalReactivePower',columnWidth:160,},
      {comment:'A相功率因数',key:'APowerFactor',columnWidth:120,},
      {comment:'B相功率因数',key:'BPowerFactor',columnWidth:160,},
      {comment:'C相功率因数',key:'CPowerFactor',columnWidth:160,},
      {comment:'总功率因数',key:'totalPowerFactor',columnWidth:160,},
      {comment:'电表类型',key:'meterType',columnWidth:160,},
      {comment:'RSRP',key:'rsrp',columnWidth:160,},
      {comment:'通讯状态',key:'communicationState',columnWidth:160,},
      {comment:'项目机构',key:'projectOrganization',columnWidth:160,},
    ]
    
    // 基础资料 - 配置分表
    Vue.prototype.$jczlPzfbTableHeader = [//表头数据
      {comment:'用户姓名',key:'userName',columnWidth:180,},
      {comment:'表名称',key:'surfaceName',columnWidth:160,},
      {comment:'表编号',key:'surfaceNum',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'操作',key:'operation',columnWidth:160,},
    ]

    // 基础资料 - 换表记录
    Vue.prototype.$jczlHbjlTableHeader = [//表头数据
      {comment:'用户名',key:'userName',columnWidth:180,},
      {comment:'用户编号',key:'userNumber',columnWidth:160,},
      {comment:'表编号',key:'surfaceNum',columnWidth:180,},
      {comment:'创建时间',key:'creatTime',columnWidth:160,},
      {comment:'纠正用量',key:'correctionDosage',columnWidth:160,},
      {comment:'纠正金额',key:'correctionAmount',columnWidth:160,},
      {comment:'旧表地址',key:'oldSurfaceAddress',columnWidth:180,},
      {comment:'新表地址',key:'newSurfaceAddress',columnWidth:160,},
      {comment:'旧表IMEI',key:'oldSurfaceiImei',columnWidth:180,},
      {comment:'新表IMEI',key:'newSurfaceImei',columnWidth:160,},
      {comment:'新表底数',key:'newBase',columnWidth:160,},
      {comment:'完成时间',key:'doneTime',columnWidth:160,},
      {comment:'状态',key:'state',columnWidth:160,},
    ]

    // 基础资料 - 数据回收站
    Vue.prototype.$jczlSjhszTableHeader = [//表头数据
      {comment:'用户编号',key:'userNumber',columnWidth:180,},
      {comment:'用户姓名',key:'userName',columnWidth:160,},
      {comment:'表名称',key:'surfaceName',columnWidth:180,},
      {comment:'表编号',key:'surfaceNum',columnWidth:160,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'项目编码',key:'projectCode',columnWidth:160,},
      {comment:'项目名称',key:'projectName',columnWidth:180,},
      {comment:'删除类型',key:'deleteType',columnWidth:160,},
      {comment:'删除日期',key:'deleteData',columnWidth:180,},
    ]



    // 抄表管理 - 抄表信息
    Vue.prototype.$cbglCbxxTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:60,},  
      {comment:'用户编号',key:'userNumber',columnWidth:80,},
      {comment:'用户名',key:'userName',columnWidth:160,},
      {comment:'安装地址',key:'installAddress',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'用量',key:'dosage',columnWidth:160,},
      {comment:'当前读数',key:'currentReading',columnWidth:160,},
      {comment:'更新时间',key:'updateTime',columnWidth:180,},
      {comment:'上次读数',key:'lastReading',columnWidth:160,},
      {comment:'上次时间',key:'lastTime',columnWidth:180,},
      {comment:'所属区域',key:'belongsArea',columnWidth:160,},  
      {comment:'身份证号',key:'IDCard',columnWidth:180,},
      {comment:'表名称',key:'surfaceName',columnWidth:160,},
      {comment:'表编号',key:'surfaceNum',columnWidth:180,},
      {comment:'类型规格',key:'typeSpecification',columnWidth:160,},
      {comment:'通信状态',key:'communicationState',columnWidth:160,},
      {comment:'表状态',key:'surfaceState',columnWidth:160,},
      {comment:'表类型',key:'surfaceType',columnWidth:180,},
      {comment:'集中器地址',key:'concentratorAddress',columnWidth:160,},
      {comment:'采集器地址',key:'collectorAddress',columnWidth:180,},
      {comment:'阀门状态',key:'valveState',columnWidth:140,},  
      {comment:'付费类型',key:'payType',columnWidth:180,},
      {comment:'手机号码',key:'mobilePhoneNum',columnWidth:160,},
      {comment:'电量',key:'electricity',columnWidth:180,},
      {comment:'电池状态',key:'batteryState',columnWidth:160,},
      {comment:'设备告警',key:'equipmentAlarm',columnWidth:160,},
      // {comment:'持续用量',key:'continuousDosage',columnWidth:160,},
      // {comment:'持续未上报',key:'continuousUnreported',columnWidth:180,},
      {comment:'数据来源',key:'dataSource',columnWidth:160,},
      {comment:'水司编码',key:'waterDepartmentCode',columnWidth:180,},
      {comment:'区域负责人编号',key:'areaPersonNum',columnWidth:140,},  
    ]

    // 抄表管理 - 定时采集
    Vue.prototype.$cbglDscjTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:60,},  
      {comment:'定时方案',key:'timingScheme',columnWidth:180,},
      {comment:'集中器地址',key:'concentratorAddress',columnWidth:160,},
      {comment:'采集器地址',key:'collectorAddress',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'操作类型',key:'operationType',columnWidth:160,},
      {comment:'间隔',key:'interval',columnWidth:160,},
      {comment:'定时类型',key:'timingType',columnWidth:180,},
      {comment:'开始时间',key:'starTime',columnWidth:160,},
      {comment:'方案所属平台',key:'programmePlatform',columnWidth:180,},
      {comment:'项目机构',key:'projectOrganization',columnWidth:140,},  
    ]

    // 抄表管理 - 设备告警记录
    Vue.prototype.$cbglSbgjjlTableHeader = [//表头数据
      {comment:'告警id',key:'alarmId',columnWidth:160,},  
      {comment:'用户编号',key:'userNumber',columnWidth:180,},
      {comment:'用户名',key:'userName',columnWidth:160,},
      {comment:'安装地址',key:'installAddress',columnWidth:180,},
      {comment:'表编号',key:'surfaceNum',columnWidth:160,},
      {comment:'表名称',key:'surfaceName',columnWidth:160,},
      {comment:'告警类型',key:'warningType',columnWidth:160,},
      {comment:'处理时间',key:'processingTime',columnWidth:180,},
      {comment:'处理情况',key:'handling',columnWidth:160,},
      {comment:'集中器地址',key:'concentratorAddress',columnWidth:180,},
      {comment:'采集器地址',key:'collectorAddress',columnWidth:140,},  
      {comment:'表地址',key:'surfaceAddress',columnWidth:180,},
      {comment:'开始时间',key:'starTime',columnWidth:160,},
      {comment:'告警信息',key:'warningLowerLimit',columnWidth:180,},
      {comment:'触发值',key:'triggerValue',columnWidth:160,},
      {comment:'处理人',key:'handler',columnWidth:160,},
      {comment:'处理备注',key:'handlingRemarks',columnWidth:160,},
    ]

    // 抄表管理 - 告警记录
    Vue.prototype.$cbglGjjlTableHeader = [//表头数据
      {comment:'用户编号',key:'userNumber',columnWidth:180,},
      {comment:'用户名',key:'userName',columnWidth:160,},
      {comment:'表id',key:'surfaceId',columnWidth:180,},
      {comment:'表编号',key:'surfaceNum',columnWidth:160,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'区域名称',key:'areaName',columnWidth:160,},
      {comment:'安装地址',key:'installAddress',columnWidth:180,},
      {comment:'用户告警',key:'userWarning',columnWidth:160,},
      {comment:'设备告警',key:'equipmentAlarm',columnWidth:160,},
      {comment:'处理情况',key:'handling',columnWidth:160,},
    ]

    // 抄表管理 - 控制记录
    Vue.prototype.$cbglKzTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:60,},  
      {comment:'集中器地址',key:'concentratorAddress',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'申请时间',key:'applyTime',columnWidth:180,},
      {comment:'控制类型',key:'controlType',columnWidth:160,},
      {comment:'执行状态',key:'executionStatus',columnWidth:160,},
      {comment:'控制时间',key:'controlTime',columnWidth:160,},
      {comment:'采集器地址',key:'collectorAddress',columnWidth:180,},
      {comment:'控制说明',key:'controlDescription',columnWidth:160,},
      {comment:'项目机构',key:'projectOrganization',columnWidth:180,},
      {comment:'操作账号',key:'operationAccount',columnWidth:140,},  
      {comment:'操作员名',key:'operatorName',columnWidth:180,},
    ]

    // 报表管理 - 抄表记录
    Vue.prototype.$bbglCbjlTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:60,},  
      {comment:'抄表编号',key:'meterReadingNo',columnWidth:180,},
      {comment:'设备ID',key:'equipmentId',columnWidth:160,},
      {comment:'用户编号',key:'userNumber',columnWidth:180,},
      {comment:'用户姓名',key:'userName',columnWidth:160,},
      {comment:'安装地址',key:'installAddress',columnWidth:160,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'读数',key:'readingNum',columnWidth:180,},
      {comment:'抄表时间',key:'meterReadingTime',columnWidth:160,},
      {comment:'通讯状态',key:'communicationState',columnWidth:180,},
      {comment:'表状态',key:'surfaceState',columnWidth:180,},  
      {comment:'表名称',key:'surfaceName',columnWidth:180,},
      {comment:'类型规格',key:'typeSpecification',columnWidth:180,},
      {comment:'区域负责人编号',key:'areaPersonNum',columnWidth:180,},
    ]

    // 报表管理 - 项目抄表率
    Vue.prototype.$bbglXmcblTableHeader = [//表头数据
      {comment:'项目编号',key:'projectCode',columnWidth:160,},  
      {comment:'项目名称',key:'projectName',columnWidth:180,},
      {comment:'总报表表数',key:'totalReportNum',columnWidth:160,},
      {comment:'应抄表次数',key:'shouldReadingTimes',columnWidth:180,},
      {comment:'成功抄表次数',key:'successReadingTimes',columnWidth:160,},
      {comment:'成功率',key:'successRate',columnWidth:160,},
    ]

    // 报表管理 - 漏损统计表1
    Vue.prototype.$bbglLstjTableHeader1 = [//表头数据
      {comment:'用户姓名',key:'userName',columnWidth:180,},
      {comment:'表名称',key:'surfaceName',columnWidth:160,},
      {comment:'表编号',key:'surfaceNum',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'总表流量',key:'zbll',columnWidth:160,},
      {comment:'分表流量合计',key:'fbllhj',columnWidth:160,},
      {comment:'流量差值',key:'llcz',columnWidth:160,},
      {comment:'漏损预警',key:'lsyj',columnWidth:160,},
    ]

    // 报表管理 - 漏损统计表2
    Vue.prototype.$bbglLstjTableHeader2 = [//表头数据
      {comment:'用户姓名',key:'userName',columnWidth:180,},
      {comment:'表名称',key:'surfaceName',columnWidth:160,},
      {comment:'表编号',key:'surfaceNum',columnWidth:180,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:160,},
      {comment:'IMEI',key:'imei',columnWidth:160,},
      {comment:'开始读数',key:'startReading',columnWidth:160,},
      {comment:'结束读数',key:'endReading',columnWidth:160,},
      {comment:'用量',key:'dosage',columnWidth:160,},
      {comment:'分表总用量',key:'submeterConsumption',columnWidth:160,},
      {comment:'漏损量',key:'leakage',columnWidth:160,},
    ]

    // 报表管理 - 夜间小流量
    Vue.prototype.$bbglYjxllTableHeader = [//表头数据
      {comment:'采集时间',key:'collectTime',columnWidth:60,},
      {comment:'累计流量(吨)',key:'cumulativeFlow',columnWidth:180,},
      {comment:'间隔差量',key:'intervalDispersion',columnWidth:180,},
    ]


    // 报表管理 - 区间统计
    Vue.prototype.$bbglQjtjTableHeader = [//表头数据
      {comment:'序号',key:'serialNumber',columnWidth:60,},
      {comment:'用户编号',key:'userNumber',columnWidth:180,},
      {comment:'用户姓名',key:'userName',columnWidth:180,},
      {comment:'安装地址',key:'installAddress',columnWidth:160,},
      {comment:'表地址',key:'surfaceAddress',columnWidth:180,},
      {comment:'表类型',key:'surfaceType',columnWidth:160,},
      {comment:'起始读数',key:'startReading',columnWidth:160,},
      {comment:'开始时间',key:'starTime',columnWidth:160,},
      {comment:'截止读数',key:'cutReading',columnWidth:160,},
      {comment:'截止时间',key:'cutTime',columnWidth:160,},
      {comment:'用量',key:'dosage',columnWidth:160,},
      {comment:'类型规格',key:'typeSpecification',columnWidth:160,},
    ]

  }
}
