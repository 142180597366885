var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          !_vm.item.children ||
          _vm.item.children.length <= 0 ||
          _vm.item.alwaysShow
            ? [
                _c(
                  "el-menu-item",
                  {
                    class: { "submenu-title-noDropdown": !_vm.isNest },
                    attrs: { index: _vm.item.path },
                  },
                  [
                    _c("i", { class: `iconfont icon-${_vm.item.meta.icon}` }),
                    _vm.item.meta && _vm.item.meta.title
                      ? _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(_vm.item.meta.title))]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            : _c(
                "el-submenu",
                { attrs: { index: _vm.item.path } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { class: `iconfont icon-${_vm.item.meta.icon}` }),
                    _vm.item.meta && _vm.item.meta.title
                      ? _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(_vm.item.meta.title) + " ")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._l(_vm.routes, function (child, index) {
                    return [
                      !child.hidden
                        ? [
                            child.children && child.children.length > 0
                              ? _c("sidebar-item", {
                                  key: index,
                                  staticClass: "nest-menu",
                                  attrs: { "is-nest": true, item: child },
                                })
                              : _c(
                                  "el-menu-item",
                                  {
                                    key: child.name,
                                    attrs: { index: child.path },
                                  },
                                  [
                                    _c("i", {
                                      class: `iconfont icon-${child.meta.icon}`,
                                    }),
                                    child.meta && child.meta.title
                                      ? _c(
                                          "span",
                                          {
                                            attrs: { slot: "title" },
                                            slot: "title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(child.meta.title) + " "
                                            ),
                                            child.meta.badge > 0 &&
                                            child.meta.badgeYn
                                              ? _c("el-badge", {
                                                  attrs: { max: 99 },
                                                  model: {
                                                    value: child.meta.badge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        child.meta,
                                                        "badge",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "child.meta.badge",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }