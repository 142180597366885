<template>
  <section class="app-main" ref="appMain">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="keepAliveDatas" v-if="keepAliveDatas.length > 0">
        <router-view :key="key"></router-view>
      </keep-alive>
      <router-view :key="key" v-else></router-view>
    </transition>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import * as categorys from '@/api/categorys'
import * as basicConfiguration from '@/api/basicConfiguration/basicConfiguration' 
import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
import {listToTreeSelect} from '@/utils'
export default {
  name: 'AppMain',
  computed: {
    ...mapGetters({
      keepAliveData: 'keepAliveData'
    }),
    ...mapActions([
        'setTenantId', //
      ]),
    keepAliveDatas() {
      return this.keepAliveData || []
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    this.$store.dispatch('setTenantId',JSON.parse(localStorage.getItem("userCode")))
    this.$basicFun.getCategorys()//获取全部字典
    this.$store.state.isTotalMeter = JSON.parse(localStorage.getItem('isTotalMeter'));
    this.getCategoryType()//获取字典类型
    // this.$basicFun.getAllArea()//获取全部区域
    this.$basicFun.getAllList()//获取全局缓存变量 - 所有List(用户、区域、项目)
    
    this.getUserId();//获取用户userId
  },
  methods:{
    ...mapActions({
      saveTypeDataLists: 'saveTypeDataLists',
      saveTypeIds: 'saveTypeIds',
      clearTypeDatas: 'clearTypeDatas'
    }),
    // 获取字典类型
    getCategoryType(){
      categorys.loadType({
        page: 1,
        limit: 99999,
        key: ''
      }).then(res => {
        this.$store.state.categoryType=[...res.data]
      }).catch(()=>{
        this.$notify({
            title: '失败',
            message: '获取字典失败，请刷新',
            type: 'error',
            duration: 2000
          })
        this.$store.state.categoryType=[]
      })
    },
    //获取用户userId
    getUserId(){
      basicDataApi.getUserId().then(response => {
        if(response.code == 200){
          this.$store.state.userId=response.result
        }
      })
    },
  },
  watch: {
    $route() {
      this.$refs.appMain.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.app-main {
    width: 100%;
    height: calc(100vh - 45px);
    /* height: calc(100vh); */
    position: relative;
    overflow: auto;
		background-color: #efefef;
    box-sizing: border-box;
}
</style>
