import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import qs from 'qs';
import { getToken } from '@/utils/auth'
import router from '@/router'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 900000, // 请求超时时间
  retry: 3,//设置全局请求次数
  retryDelay: 1000,//设置全局请求间隙
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),//配置axios参数序列化,把中括号等特殊字符序列化

})

// // 重复请求时：取消重复请求，只保留最后一次请求
let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
let cancelToken = axios.CancelToken;
let removePending = (config) => {
  for(let p in pending){
    if(pending[p].u === config.url.split('?')[0] + '&' + config.method) { 
      //当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //数组移除当前请求
    }
  }
}
// 重复请求时：取消重复请求，只保留最后一次请求

// request拦截器-请求前
service.interceptors.request.use(config => {
  // 重复请求处理
  removePending(config); //在一个axios发送前执行一下取消操作
  config.cancelToken = new cancelToken((c)=>{// pending存放每一次请求的标识，一般是url + 参数名 + 请求方法，当然你可以自己定义
    pending.push({ u: config.url.split('?')[0] +'&' + config.method, f: c});//config.data为请求参数
  });
  // 重复请求处理
// console.log(store.getters.tenantid,2222)
// console.log( localStorage.getItem("userCode"),789999)
  config.headers['tenantId'] =JSON.parse(localStorage.getItem("userCode")) //store.getters.tenantid

  if (store.getters.token) {
    config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  if (store.getters.isIdentityAuth) {
    config.headers['Authorization'] = 'Bearer ' + store.getters.oidcAccessToken
  }

  return config
}, error => {
  //超时处理
  var config = error.config;
  if (!config || !config.retry) return Promise.reject(error);
  // 设置用于跟踪重试次数的变量
  config.__retryCount = config.__retryCount || 0;
 
  // 检查我们是否已将重试总数最大化
  if (config.__retryCount >= config.retry) {
    // 错误拒绝
    return Promise.reject(error);
  }
 
  // 增加重试次数
  config.__retryCount += 1;
 
  // 创造新的承诺来处理指数退避
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });

  pending = [];//清空记录

  // 返回承诺，其中将撤回axios以重试请求
  return backoff.then(function () {
    return axios(config);
  });
  // Do something with request error
  // console.log(error) // for debug
  // Promise.reject(error)
})

// respone拦截器-响应后
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    const res = response.data
    if (res.code !== 200) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm('登录已超时，可以【取消】继续留在该页面，或者【重新登录】', '超时提醒', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      } else {
        Message({
          message: res.message || res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject('error')
    } else {
      // 清除当前记录
      for(let p in pending){
        if(pending[p].u === response.config.url.split('?')[0] + '&' + response.config.method) {
          pending.splice(p, 1); //数组移除当前请求
        }
      }
      return response.data
    }
  },
  error => {
    if(error.response&&error.response.data.code==401&&error.response.data.message=='认证失败，请提供认证信息'){
      Message({
        message: error.response.data.message,
        type: 'error',
        duration: 10 * 1000
      })
      store.dispatch('LogOut').then(() => {
        router.push({ path: '/login' })
        location.reload()// 为了重新实例化vue-router对象 避免bug
      })
      return
    }
    if(error.response && error.response.status == 400){  //调用远程服务时，前端类型和后端不匹配
      var message = '<strong>'+error.response.data.detail +'</strong><br>'
      //异常信息需要特殊处理一下
      var errors = Object.entries(error.response.data.errors).map(([key, value]) => ({key,value}));
      errors.forEach(item=>{
        message +=item.key +":"

        item.value.forEach(dtl =>{
          message +=" " +dtl;
        })

        message +="<br>";
      })
      Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'error',
        duration: 10 * 1000
      })
    }else{
      if(error.message){
        Message({
          message: '请先启动WebApi，再刷新本页面，异常详情：' + error.message,
          type: 'error',
          duration: 10 * 1000
        })
      }
    }
    pending = [];//清空记录

    return Promise.reject(error)
  }
)

export default service

//get
export function autoGetMeth(data,url){
  return service.get(url+"?"+qs.stringify(data));
}
//post
export function autoPostMeth(data,url){
  return service.post(url,qs.stringify(data));
}

