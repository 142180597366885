var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticStyle: { border: "1px solid #eee" } },
    [
      _c(
        "el-main",
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-tag", [
                    _vm._v(
                      "这是一个表单详情展示样例，你可以完全根据客户的需求进行排版"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请假类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.requestType,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "requestType", $$v)
                        },
                        expression: "temp.requestType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "病假" } }),
                      _c("el-radio", { attrs: { label: "事假" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请假人", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { name: "name", disabled: _vm.disabled("userName") },
                    model: {
                      value: _vm.temp.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "userName", $$v)
                      },
                      expression: "temp.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("startDate"),
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.temp.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "startDate", $$v)
                          },
                          expression: "temp.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "line",
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 2 },
                    },
                    [_vm._v("-")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("startTime"),
                          type: "fixed-time",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.temp.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "startTime", $$v)
                          },
                          expression: "temp.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("endDate"),
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.temp.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "endDate", $$v)
                          },
                          expression: "temp.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "line",
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 2 },
                    },
                    [_vm._v("-")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("endTime"),
                          type: "fixed-time",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.temp.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "endTime", $$v)
                          },
                          expression: "temp.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    size: "small",
                    label: "请假说明",
                    prop: "requestComment",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: _vm.disabled("requestComment"),
                      rows: 3,
                    },
                    model: {
                      value: _vm.temp.requestComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requestComment", $$v)
                      },
                      expression: "temp.requestComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-carousel",
                { attrs: { interval: 4000, type: "card", height: "150px" } },
                _vm._l(_vm.temp.files, function (file) {
                  return _c("el-carousel-item", { key: file.filePath }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.baseURL + "/" + file.filePath,
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            height: "150",
                            src: _vm.baseURL + "/" + file.filePath,
                            title: file.fileName,
                          },
                        }),
                      ]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }