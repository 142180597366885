/* * 
 * @description: 通用方法
 * @author: Long
 * @version: 1.0
 * @updateDate:2022-10-13
 * */
import store from "@/store"  
import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
import {listToTreeSelect} from '@/utils'
import { Notification } from 'element-ui';

const basicFun = {}
// 获取全局缓存变量 - 所有List(用户、区域、项目)
basicFun.getAllList = function(){
  var data={
    page: 1,
    limit: 9999,
  }
  basicDataApi.getAllList(data).then(response => {
    if(response.code == 200){
      // console.log(response,741147)
      // 获取所有分支机构处理
      var Process = response.result.Org.map(function(item) {
        return {
          id: item.id,
          value:item.id,
          childId: item.id,
          label: item.name,
          parentId: item.parentId || null
        }
      })
      store.state.AllAreaGetTreeId = response.result.HdybArea
      var orgstmp = JSON.parse(JSON.stringify(Process)) 
      store.state.allInstitution = listToTreeSelect(orgstmp)

      // var filterNoSurface = response.result.HdybArea.filter( u => u.meterNum > 0)
      // // 获取区域处理
      // var a = filterNoSurface.map(function(item) {
      //   return {
      //     id: item.id,
      //     childId: item.id,
      //     label: item.areaName + '(' + item.meterNum + ')',
      //     parentId: item.fatherNode || null
      //   }
      // })
      // var b = JSON.parse(JSON.stringify(a))
      // store.state.AllAreaNoSurface = listToTreeSelect(b)
      // // 获取区域处理

      store.state.AllUser = response.result.User//获取全部用户
      store.state.AllProject = response.result.HdybProject//获取全部项目
      store.state.AllEquipmentType = response.result.HdybEquipmentType//获取全部类型规格
      store.state.HdybScheduledAcquisition = response.result.HdybScheduledAcquisition//获取全部定时任务
      // store.state.isTotalMeter =response.result.HdybUserOperateRelationTbl[0].value  ;//是否是总表查询
    }else{
      store.state.AllUser = []
      store.state.AllProject = []
      store.state.AllEquipmentType = []
      store.state.AllAreaGetTreeId = []
      store.state.AllArea = []
      store.state.HdybScheduledAcquisition = []
      Notification({
        message: response.message,
        type: 'warning',
        duration: 2000
      });
    }
  }).catch(()=>{
    Notification({
      title: '失败',
      message: '获取基础数据失败，请刷新',
      type: 'warning',
      duration: 2000
    });
    store.state.AllUser = []
    store.state.AllProject = []
    store.state.AllEquipmentType = []
    store.state.AllAreaGetTreeId = []
    store.state.AllArea = []
  })
}
// 获取全部用户
basicFun.getAllUser = function(){
  var data={
    page: 1,
    limit: 9999,
  }
  basicDataApi.getAllUser(data).then(response => {
    if(response.code == 200){
      store.state.AllUser = response.result
    }else{
      store.state.AllUser = []
      Notification({
        message: response.message,
        type: 'warning',
        duration: 2000
      });
    }
  }).catch(()=>{
    Notification({
      title: '失败',
      message: '获取全部用户失败，请刷新',
      type: 'warning',
      duration: 2000
    });
    store.state.AllUser = []
  })
}

// 获取全部区域
basicFun.getAllArea = function(){
  var data={
    page: 1,
    limit: 9999,
  }
  basicDataApi.qyglGetList(data).then(response => {
    if(response.code == 200){
      var Process = response.data.map(function(item) {
        return {
          id: item.id,
          label: item.areaName,
          parentId: item.fatherNode || null
        }
      })
      store.state.AllAreaGetTreeId = response.data
      var orgstmp = JSON.parse(JSON.stringify(Process))
      store.state.AllArea = listToTreeSelect(orgstmp)

      // this.$forceUpdate() //请求数据后强制更新视图
    }else{
      store.state.AllArea = []
      Notification({
        message: response.message,
        type: 'warning',
        duration: 2000
      });
    }
  }).catch(()=>{
    Notification({
      title: '失败',
      message: '获取全部区域失败，请刷新',
      type: 'warning',
      duration: 2000
    });
    store.state.AllArea = []
  })
}

// 获取全部字典
basicFun.getCategorys = function(){
  basicDataApi.getAllCategorys().then(response => {
    // console.log(response,7899999)
    if(response.code == 200){
      store.state.allCategorysData = response.result
      response.result.forEach((item)=>{
        if(item.id=="Hdyb_TemplateType"){//上传模板类型
          store.state.TemplateType = item.categoryList
        }
        if(item.id=="upLoadType"){//上传类型
          store.state.upLoadTypeData = item.categoryList
        }
        if(item.id=="basicState"){//基础权限状态
          store.state.basicState = item.categoryList
        }
        if(item.id=="HS_DeviceType"){//设备类型
          store.state.deviceType = item.categoryList
        }
        if(item.id=="HS_CropType"){//作物类型
          store.state.cropType = item.categoryList
        }
        if(item.id=="SYS_UserYn"){
          store.state.useryn = item.categoryList  //是否可用
        }
        if(item.id=="SYS_LogType"){//日志类型
          store.state.logType = item.categoryList
        }
      })
      // this.$forceUpdate() //请求数据后强制更新视图
    }else{
      store.state.TemplateType = []
      Notification({
        message: response.message,
        type: 'warning',
        duration: 2000
      });
    }
  }).catch(()=>{
    Notification({
      title: '失败',
      message: '获取字典失败，请刷新',
      type: 'warning',
      duration: 2000
    });
    store.state.TemplateType = []
  })
}

//时间转换
basicFun.dataFormat = function(date, fmt){
  if (date) {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
      }
    }
    return fmt
  } else {
    return '';
  }
}
function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}


//获取日期
basicFun.getDay = function (day){
  var today = new Date();
  var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  return today
}
function handleClick(event){
  if(document.getElementsByClassName('dragBox_mid').length>0){
    if(event.target.getAttribute("class")=="el-tooltip hamburger-container icon iconfont icon-caozuo_suoxiao"){ //打开  
      var mid = document.getElementsByClassName('dragBox_mid');
      let width = mid[0].offsetWidth 
        mid[0].style.width= (width -170)+'px'
    }else if( event.target.getAttribute("class")=="el-tooltip hamburger-container icon iconfont icon-caozuo_quanpingfangda"){
      var mid = document.getElementsByClassName('dragBox_mid');
      let width = mid[0].offsetWidth 
        mid[0].style.width= (width +170)+'px'
    }
  }else{
    return
  }
}

//拖动div方法
basicFun.dragControllerDiv = function (){
  // document.addEventListener("click", handleClick);
  var resize = document.getElementsByClassName('dragBox_resize');
  var left = document.getElementsByClassName('dragBox_left');
  var mid = document.getElementsByClassName('dragBox_mid');
  var dragBox = document.getElementsByClassName('dragBox');
  // 初始化宽度-取store里存的值
  resize[0].style.left = store.state.moveLen; // 设置左侧区域的宽度
  left[0].style.width = store.state.moveLen + 'px';
  mid[0].style.width = (dragBox[0].clientWidth - store.state.moveLen - 10 - 20) + 'px';
  // 初始化宽度
  for (let i = 0; i < resize.length; i++) {
    // 鼠标按下事件
    resize[i].onmousedown = function (e) {
      //颜色改变提醒
      resize[i].style.background = '#1a96d4';
      var startX = e.clientX;
      resize[i].left = resize[i].offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = function (e) {
        var endX = e.clientX;
        var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        var maxT = dragBox[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        if (moveLen < 300) moveLen = 300; // 左边区域的最小宽度为32px
        if (moveLen > maxT - 600) moveLen = maxT - 600; //右边区域最小宽度为150px

        store.state.moveLen = moveLen
        resize[i].style.left = store.state.moveLen; // 设置左侧区域的宽度

        for (let j = 0; j < left.length; j++) {
          left[j].style.width = store.state.moveLen + 'px';
          mid[j].style.width = (dragBox[i].clientWidth - store.state.moveLen - 10 - 20) + 'px';
        }
      };
      // 鼠标松开事件
      document.onmouseup = function (evt) {
        //颜色恢复
        resize[i].style.background = '#d6d6d6';
        document.onmousemove = null;
        document.onmouseup = null;
        resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
      };
      resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
      return false;
    };
  }
}



export default basicFun