/* * 
 * @description: 通用搜索js
 * @author: Long
 * @version: 1.0
 * @updateDate:2022-6-13
 * @description: 通用的表单项，定义如下：
    columnKey：参数名
    columnDescription：中文名（描述）
    columnType：参数类型
      text：文本（input）      selectConversion：通过字典转换的select选择器      auto：自动完成（远程搜索）选择器      date：日期      datetime：时间      textarea：多行文本
 * */
import store from "@/store"
export default {
  install(Vue) {

    // 组态系统 - 组态管理
    Vue.prototype.$ztglSearch = {
      column:[
        {columnKey:'key',columnDescription: "项目名称",columnType:'input',placeholder:'请输入项目名称',searchWidth:160,},
      ],
    }

    // 基础配置 - 项目管理
    Vue.prototype.$jcpzXmglSearch = {
      column:[
        {columnKey:'key',columnDescription: "项目名称",columnType:'input',placeholder:'项目名称/公司名称/联系人/联系电话',searchWidth:250,},
      ],
    }


    // 基础资料 - 数据导入
    Vue.prototype.$jczlSjdrSearch = {
      column:[
        {columnKey:'key',columnDescription: "任务名",columnType:'input',placeholder:'请输入任务名',searchWidth:160,},
        {
          columnKey:'outType',
          columnDescription: "导出",
          columnType:'dropdown',
          placeholder:'请选择导出类型',
          isSearch:false,//是否是搜索栏
          isInstruction:true,//是否是指令
          dropdownItem:[
            {name:'源文件',dtCode:'0',command:'0',},
            {name:'成功文件',dtCode:'1',command:'1',},
            {name:'失败文件',dtCode:'2',command:'2',},
          ],
        },
      ],
    }

    // 基础资料 - 区域管理
    Vue.prototype.$jczlQyglSearch = {
      column:[
        {columnKey:'areaName',columnDescription: "区域名称",columnType:'input',placeholder:'请输入区域名称',searchWidth:160,},
        {
          columnKey:'isMountDevice',
          columnDescription: "挂载设备",
          columnType:'select',
          placeholder:'挂载设备',
          seleceOption:[
            {name:'是',dtCode:'1',},
            {name:'否',dtCode:'2',},
          ],
          typeId:'Hyb_HaveDevice',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
        {
          columnKey:'isMountMap',
          columnDescription: "挂载地图",
          columnType:'select',
          placeholder:'挂载地图',
          seleceOption:[
            {name:'是',dtCode:'1',},
            {name:'否',dtCode:'2',},
          ],
          typeId:'Hyb_HaveMap',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
      ],
    }
    

    // 基础资料 - 集中器管理
    Vue.prototype.$jczlJzqglSearch = {
      column:[
        {columnKey:'key',columnDescription: "集中器名称/集中器地址",columnType:'input',placeholder:'集中器名称/集中器地址',searchWidth:160,},
        {
          columnKey:'nowState',
          columnDescription: "当前状态",
          columnType:'select',
          placeholder:'当前状态',
          seleceOption:[
            {name:'全部',dtCode:'0',},
            {name:'已上线',dtCode:'1',},
            {name:'未上线',dtCode:'2',},
          ],
          typeId:'Hyb_ConcentratorStatus',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
        // {
        //   columnKey:'isMountMap',
        //   columnDescription: "挂载地图",
        //   columnType:'select',
        //   placeholder:'挂载地图',
        //   seleceOption:[
        //     {name:'是',dtCode:'1',},
        //     {name:'否',dtCode:'2',},
        //   ],
        // },
      ],
    }

    // 基础资料 - 户表管理
    Vue.prototype.$jczlhbglSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "查询方式",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'queryKey',
          columnDescription: "用户编号",
          columnType:'select',
          placeholder:'请选择',
          seleceOption:[
            {name:'用户编号',dtCode:'1',},
            {name:'表编号',dtCode:'2',},
            {name:'表地址',dtCode:'3',},
            {name:'IMEI',dtCode:'4',},
            {name:'用户id',dtCode:'5',},
          ],
          typeId:'Hyb_MeterAccurateSearchType',//从全局数据字典里获取下拉数据
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'queryValue',columnDescription: "用户编号",columnType:'input',placeholder:'用户编号',searchWidth:160,},
        {
          columnKey:'UserWarning',
          columnDescription: "告警类型",
          columnType:'select',
          placeholder:'请选择告警类型',
          seleceOption:[
            {name:'用户告警',dtCode:'1',},
          ],
          typeId:'Hyb_MeterSearchWarningType',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
      ],
    }

    // 基础资料 - 总表管理
    Vue.prototype.$jczlZbglSearch = {
      column:[
        {columnKey:'key',columnDescription: "设备名称/设备地址",columnType:'input',placeholder:'设备名称/设备地址',searchWidth:160,},
        // {
        //   columnKey:'nowState',
        //   columnDescription: "当前状态",
        //   columnType:'select',
        //   placeholder:'当前状态',
        //   seleceOption:[
        //     {name:'全部',dtCode:'0',},
        //     {name:'已上线',dtCode:'1',},
        //     {name:'未上线',dtCode:'2',},
        //   ],
        //   typeId:'Hyb_ConcentratorStatus',//从全局数据字典里获取下拉数据
        //   clearable:true,//能不能清空
        // },
        // {
        //   columnKey:'isMountMap',
        //   columnDescription: "挂载地图",
        //   columnType:'select',
        //   placeholder:'挂载地图',
        //   seleceOption:[
        //     {name:'是',dtCode:'1',},
        //     {name:'否',dtCode:'2',},
        //   ],
        // },
      ],
    }


    // 基础资料 - 类型管理
    Vue.prototype.$jczllxglSearch = {
      column:[
        {columnKey:'key',columnDescription: "类型名称",columnType:'input',placeholder:'请输入类型名称',searchWidth:160,},
      ],
    }

    // 基础资料 - NB-IOT注册表
    Vue.prototype.$jczlNbiotzcbSearch = {
      column:[
        {columnKey:'key',columnDescription: "IMEI/表地址",columnType:'input',placeholder:'请输入IMEI或表地址',searchWidth:160,},
        {
          columnKey:'gjtype',
          columnDescription: "查询条件",
          columnType:'select',
          placeholder:'请选择查询条件',
          seleceOption:[
            {name:'订单查询',dtCode:'1',},
            {name:'绑定状态',dtCode:'2',},
            {name:'阀门状态',dtCode:'3',},
            {name:'更新时间',dtCode:'4',},
            {name:'电池状态',dtCode:'5',},
            {name:'设备类型',dtCode:'6',},
            {name:'读数',dtCode:'7',},
            {name:'上报周期',dtCode:'8',},
            {name:'信号强度',dtCode:'9',},
            {name:'版本号',dtCode:'10',},
            {name:'销售订单号',dtCode:'11',},
          ],
          clearable:true,//能不能清空
        },
        // {
        //   columnKey:'czzl',
        //   columnDescription: "操作指令",
        //   columnType:'dropdown',
        //   placeholder:'操作指令',
        //   dropdownItem:[
        //     {name:'设置底数',command:'1',},
        //     {name:'设置表地址',command:'2',},
        //     {name:'设置上报周期',command:'3',},
        //     {name:'设置上报时间',command:'4',},
        //     {name:'设置冻结频率',command:'5',},
        //     {name:'设置密集冻结频率',command:'6',},
        //     {name:'设置无流量告警时间',command:'7',},
        //     {name:'设置过量自动关阀',command:'8',},
        //     {name:'开阀',command:'9',divided:true,},
        //     {name:'关阀',command:'10',},
        //     {name:'阀门摆动',command:'11',},
        //   ],
        // },
      ],
    }

    // 基础资料 - 批量处理设备
    Vue.prototype.$jczlPlclsbSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "查询方式",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'1',},
            {name:'模糊查询',dtCode:'2',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          searchWidth:120,
          clearable:false,//能不能清空
        },
        {
          columnKey:'queryKey',
          columnDescription: "查询条件",
          columnType:'select',
          placeholder:'查询条件',
          seleceOption:[
            {name:'用户编号',dtCode:'1',},
            {name:'表编号',dtCode:'2',},
            {name:'IMEI',dtCode:'3',},
            {name:'附加收费方式',dtCode:'4',},
            {name:'收费类型',dtCode:'5',},
          ],
          typeId:'Hyb_BatchDealAccurateSearchType',//从全局数据字典里获取下拉数据
          searchWidth:120,
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'queryValue',columnDescription: "查询",columnType:'input',placeholder:'用户编号',searchWidth:160,},
        // {
        //   columnKey:'billingType',
        //   columnDescription: "计费方式",
        //   columnType:'select',
        //   placeholder:'计费方式',
        //   seleceOption:[
        //     {name:'全部',dtCode:'1',},
        //     {name:'不计费',dtCode:'2',},
        //     {name:'按天收费',dtCode:'3',},
        //     {name:'按月收费',dtCode:'4',},
        //   ],
        //   typeId:'Hyb_BatchDealChargeType',//从全局数据字典里获取下拉数据
        //   searchWidth:120,
        //   clearable:true,//能不能清空
        // },
      ],
    }

    // 基础资料 - NB-IOT电表
    Vue.prototype.$jczlNbiotdbSearch = {
      column:[
        {columnKey:'key',columnDescription: "IMEI或表地址",columnType:'input',placeholder:'请输入IMEI或表地址',searchWidth:160,},
        // {
        //   columnKey:'czzl',
        //   columnDescription: "操作指令",
        //   columnType:'dropdown',
        //   placeholder:'操作指令',
        //   dropdownItem:[
        //     {name:'电表数据读取',command:'1',},
        //     {name:'电表跳闸',command:'2',},
        //     {name:'电表合闸',command:'3',},
        //   ],
        // },
      ],
    }

    // 基础资料 - 配置分表
    Vue.prototype.$jczlPzfbSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'cxnr',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'表编号',dtCode:'1',},
            {name:'表地址',dtCode:'2',},
            {name:'IMEI',dtCode:'3',},
          ],
          typeId:'Hyb_MainDetailAccurateSearchType',//从全局数据字典里获取下拉数据
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'key',columnDescription: "表编号",columnType:'input',placeholder:'表编号',searchWidth:160,},
      ],
    }

    // 基础资料 - 换表记录
    Vue.prototype.$jczlHbjlSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'queryKey',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'用户编号',dtCode:'1',},
            {name:'表编号',dtCode:'2',},
          ],
          typeId:'Hyb_ChangeMeterRecordAccurateSearchType',//从全局数据字典里获取下拉数据
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'queryValue',columnDescription: "用户编号",columnType:'input',placeholder:'用户编号',searchWidth:160,},
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
      ],
    }

    // 基础资料 - 数据回收站
    Vue.prototype.$jczlSjhszSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'queryKey',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'用户编号',dtCode:'1',},
            {name:'表编号',dtCode:'2',},
            {name:'表地址',dtCode:'3',},
          ],
          typeId:'Hyb_DataRecycleAccurateSearchType',//从全局数据字典里获取下拉数据
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'queryValue',columnDescription: "表编号",columnType:'input',placeholder:'表编号',searchWidth:160,},
      ],
    }

    // 抄表管理 - 抄表信息
    Vue.prototype.$cbglCbxxSearch = {
      column:[
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        // {
        //   columnKey:'queryType',
        //   columnDescription: "精准查询",
        //   columnType:'select',
        //   placeholder:'查询方式',
        //   seleceOption:[
        //     {name:'精准查询',dtCode:'0',},
        //     {name:'模糊查询',dtCode:'1',},
        //   ],
        //   typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
        //   searchWidth:120,
        //   clearable:false,//能不能清空
        // },
        // {
        //   columnKey:'queryKey',
        //   columnDescription: "查询内容",
        //   columnType:'select',
        //   placeholder:'查询内容',
        //   seleceOption:[
        //     {name:'用户编号',dtCode:'1',},
        //     {name:'用户姓名',dtCode:'2',},
        //     {name:'表编号',dtCode:'3',},
        //     {name:'表安装地址',dtCode:'4',},
        //     {name:'表地址',dtCode:'5',},
        //   ],
        //   typeId:'Hyb_MeterReadAccurateSearchType',//从全局数据字典里获取下拉数据
        //   isDynamic:true,//是否动态获取key的placeholder
        //   searchWidth:120,
        //   clearable:true,//能不能清空
        // },
        {columnKey:'queryValue',columnDescription: "关键字",columnType:'input',placeholder:'关键字',searchWidth:160,},
        
      ],
    }

    // 抄表管理 - 设备升级
    Vue.prototype.$cbglDscjSearch = {
      column:[
        {columnKey:'key',columnDescription: "用名称",columnType:'input',placeholder:'版本号',searchWidth:200,},
      ],
    }

    // 抄表管理 - 设备告警记录
    Vue.prototype.$cbglSbgjjlSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'cxnr',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'用户编号',dtCode:'1',},
            {name:'表编号',dtCode:'2',},
            {name:'表地址',dtCode:'3',},
            {name:'告警id',dtCode:'4',},
          ],
          clearable:true,//能不能清空
        },
        {columnKey:'key',columnDescription: "用户编号",columnType:'input',placeholder:'用户编号',searchWidth:160,},
        {
          columnKey:'cxtj',
          columnDescription: "查询条件",
          columnType:'select',
          placeholder:'请选择查询条件',
          seleceOption:[
            {name:'时间搜索',dtCode:'1',},
            {name:'告警搜索',dtCode:'2',},
            {name:'处理搜索',dtCode:'3',},
          ],
          clearable:true,//能不能清空
        },
        {
          columnKey:'sfcl',
          columnDescription: "是否处理",
          columnType:'select',
          placeholder:'请选择是否处理',
          seleceOption:[
            {name:'已经处理',dtCode:'1',},
            {name:'未处理',dtCode:'2',},
          ],
          clearable:true,//能不能清空
        },
      ],
    }

    // 抄表管理 - 用户告警记录
    Vue.prototype.$cbglYhgjjlSearch = {
      column:[
        // {
        //   columnKey:'queryType',
        //   columnDescription: "精准查询",
        //   columnType:'select',
        //   placeholder:'请选择查询方式',
        //   seleceOption:[
        //     {name:'精准查询',dtCode:'0',},
        //     {name:'模糊查询',dtCode:'1',},
        //   ],
        //   typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
        //   clearable:false,//能不能清空
        //   searchWidth:100,
        // },
        // {
        //   columnKey:'queryKey',
        //   columnDescription: "查询内容",
        //   columnType:'select',
        //   placeholder:'请选择关键字',
        //   seleceOption:[
        //     {name:'用户编号',dtCode:'1',},
        //     {name:'用户名',dtCode:'2',},
        //   ],
        //   typeId:'Hyb_AlarmLogAccurateSearchType',//从全局数据字典里获取下拉数据
        //   isDynamic:true,//是否动态获取key的placeholder
        //   clearable:true,//能不能清空
        //   searchWidth:100,
        // },
        {columnKey:'queryValue',columnDescription: "关键字",columnType:'input',placeholder:'关键字',searchWidth:140,},
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {
          columnKey:'abnormalState',
          columnDescription: "告警类型",
          columnType:'select',
          placeholder:'告警类型',
          seleceOption:[
            {name:'时间搜索',dtCode:'1',},
            {name:'处理搜索',dtCode:'2',},
          ],
          typeId:'Hyb_AbnormalState',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
        {
          columnKey:'processingStatus',
          columnDescription: "处理状态",
          columnType:'select',
          placeholder:'处理状态',
          seleceOption:[
            {name:'已经处理',dtCode:'1',},
            {name:'未处理',dtCode:'2',},
          ],
          typeId:'Hyb_ProcessingStatus',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
          searchWidth:100,
        },
      ],
    }

    // 抄表管理 - 控制记录
    Vue.prototype.$cbglKzjlSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'queryKey',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'表地址',dtCode:'1',},
            {name:'集中器地址',dtCode:'2',},
          ],
          typeId:'Hyb_ControlRecordAccurateSearchType',//从全局数据字典里获取下拉数据
          isDynamic:true,//是否动态获取key的placeholder
          clearable:true,//能不能清空
        },
        {columnKey:'queryValue',columnDescription: "集中器地址",columnType:'input',placeholder:'集中器地址',searchWidth:160,},
        // {
        //   columnKey:'otherKey',
        //   columnDescription: "查询条件",
        //   columnType:'select',
        //   placeholder:'请选择查询条件',
        //   seleceOption:[
        //     {name:'控制类型',dtCode:'1',},
        //     {name:'执行状态',dtCode:'2',},
        //     {name:'申请时间',dtCode:'3',},
        //     {name:'控制时间',dtCode:'4',},
        //   ],
        //   typeId:'Hyb_ControlOtherSearchType',//从全局数据字典里获取下拉数据
        // },
      ],
    }

    // 报表管理 - 抄表历史
    Vue.prototype.$bbglCbjlSearch = {
      column:[
        // {
        //   columnKey:'queryType',
        //   columnDescription: "精准查询",
        //   columnType:'select',
        //   placeholder:'请选择查询方式',
        //   seleceOption:[
        //     {name:'精准查询',dtCode:'0',},
        //     {name:'模糊查询',dtCode:'1',},
        //   ],
        //   typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
        //   searchWidth:100,
        //   clearable:false,//能不能清空
        // },
        // {
        //   columnKey:'queryKey',
        //   columnDescription: "查询内容",
        //   columnType:'select',
        //   placeholder:'请选择关键字',
        //   seleceOption:[
        //     {name:'用户编号',dtCode:'1',},
        //     {name:'用户姓名',dtCode:'2',},
        //     {name:'抄表编号',dtCode:'3',},
        //     {name:'表地址',dtCode:'4',},
        //     {name:'设备id',dtCode:'5',},
        //   ],
        //   isDynamic:true,//是否动态获取key的placeholder
        //   searchWidth:100,
        //   clearable:true,//能不能清空
        //   typeId:'Hyb_RptMeterReadRecordAccurateSearchType',//从全局数据字典里获取下拉数据
        // },
        {columnKey:'queryValue',columnDescription: "查询内容",columnType:'input',placeholder:'关键字',searchWidth:120,},
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        // {columnKey:'isTotalMeter',columnDescription: "切换总表户表",columnType:'switch',activeText:'总表',inactiveText:'户表',activeColor:'#1a96d4',},
      ],
    }

    // 报表管理 - 项目抄表率
    Vue.prototype.$bbglXmcblSearch = {
      column:[
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {columnKey:'key',columnDescription: "区域名称",columnType:'input',placeholder:'请输入区域名称',searchWidth:200,},
        {columnKey:'isTotalMeter',columnDescription: "切换总表户表",columnType:'switch',activeText:'总表',inactiveText:'户表',activeColor:'#1a96d4',},
        // {
        //   columnKey:'cxnr',
        //   columnDescription: "查询类型",
        //   columnType:'select',
        //   placeholder:'请选择查询类型',
        //   seleceOption:[
        //     {name:'水表',dtCode:'1',},
        //     {name:'气表',dtCode:'2',},
        //     {name:'电表',dtCode:'3',},
        //     {name:'热表',dtCode:'4',},
        //     {name:'NB设备',dtCode:'5',},
        //   ],
        //   clearable:true,//能不能清空
        // },
      ],
    }

    // 报表管理 - 漏损统计
    Vue.prototype.$bbglLstjSearch = {
      column:[
        {
          columnKey:'queryType',
          columnDescription: "精准查询",
          columnType:'select',
          placeholder:'请选择查询方式',
          seleceOption:[
            {name:'精准查询',dtCode:'0',},
            {name:'模糊查询',dtCode:'1',},
          ],
          typeId:'Hyb_SearchType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
        },
        {
          columnKey:'cxnr',
          columnDescription: "查询内容",
          columnType:'select',
          placeholder:'请选择关键字',
          seleceOption:[
            {name:'表编号',dtCode:'1',},
            {name:'表地址',dtCode:'2',},
            {name:'IMEI',dtCode:'3',},
          ],
          clearable:true,//能不能清空
        },
        {columnKey:'key',columnDescription: "表编号",columnType:'input',placeholder:'表编号',searchWidth:160,},
      ],
    }

    // 报表管理 - 漏损统计-子表
    Vue.prototype.$bbglLstjSearchChild = {
      column:[
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePicker',},
      ],
    }

    // 报表管理 - 夜间小流量统计
    Vue.prototype.$bbglYjxlljSearch = {
      column:[
        {columnKey:'searchTime',columnDescription: "请选择日期",columnType:'date',},
      ],
    }

    // 报表管理 - 用量统计
    Vue.prototype.$bbglQjtjSearch = {
      column:[
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        // {
        //   columnKey:'cxnr',
        //   columnDescription: "查询内容",
        //   columnType:'select',
        //   placeholder:'请选择关键字',
        //   seleceOption:[
        //     {name:'用户编号',dtCode:'1',},
        //     {name:'用户姓名',dtCode:'2',},
        //     {name:'表地址',dtCode:'3',},
        //     {name:'表类型',dtCode:'4',},
        //     {name:'用量区间',dtCode:'5',},
        //   ],
        //   clearable:true,//能不能清空
        // },
        // {columnKey:'key',columnDescription: "用户编号",columnType:'input',placeholder:'用户编号',searchWidth:160,},
      ],
    }
    // 报表管理 - 用量明细
    Vue.prototype.$bbglYlmxSearch = {
      column:[
        {columnKey:'userNumber',columnDescription: "用户编号",columnType:'input',placeholder:'用户编号',searchWidth:160,},
        {columnKey:'surfaceNum',columnDescription: "表编号",columnType:'input',placeholder:'表编号',searchWidth:160,},
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {columnKey:'isTotalMeter',columnDescription: "切换总表户表",columnType:'switch',activeText:'总表',inactiveText:'户表',activeColor:'#1a96d4',},
      ],
    }

    

    // 公共查询条件
    // 附件管理
    Vue.prototype.$attachmentSearch = {
      column:[
        {columnKey:'key',columnDescription: "文件名称",columnType:'input',placeholder:'文件名称/路径/文件类型'},
      ],
    }

    // 系统日志
    Vue.prototype.$messageSearch = {
      column:[
        {
          columnKey:'logTypeName',
          columnDescription: "日志类型",
          columnType:'select',
          placeholder:'请选择日志类型',
          select:store.state.logType,
          clearable:true,//能不能清空
        },
        {columnKey:'key',columnDescription: "日志内容",columnType:'input',placeholder:'日志内容/日志分类'},
      ],
    }

    // 我的消息
    Vue.prototype.$myMessageSearch = {
      column:[
        {columnKey:'key',columnDescription: "消息内容",columnType:'input',placeholder:'消息内容'},
      ],
    }

    // 流程中心 - 待处理流程
    Vue.prototype.$processDcllcSearch = {
      column:[
        {columnKey:'key',columnDescription: "名称",columnType:'input',placeholder:'名称'},
      ],
    }

    // 流程中心 - 我的流程
    Vue.prototype.$processMylcSearch = {
      column:[
        {columnKey:'key',columnDescription: "名称",columnType:'input',placeholder:'名称'},
      ],
    }

    // 流程中心 - 已处理流程
    Vue.prototype.$processYclSearch = {
      column:[
        {columnKey:'key',columnDescription: "名称",columnType:'input',placeholder:'名称'},
      ],
    }

    // 基础配置 - 客户管理
    Vue.prototype.$basicConfigKhglSearch = {
      column:[
        {columnKey:'isFactory',columnDescription: "客户",columnType:'checkbox',},
        {columnKey:'isPurchaser',columnDescription: "供应商",columnType:'checkbox',},
        {columnKey:'key',columnDescription: "客户编码",columnType:'input',placeholder:'客户编码/客户名称'},
      ],
    }
    // 基础配置 - 项目编码
    Vue.prototype.$basicConfigXmbmSearch = {
      column:[
        {columnKey:'key',columnDescription: "项目名称",columnType:'input',placeholder:'项目名称/项目编码'},
      ],
    }

    // 基础配置 - 系统管理 - 模块管理
    Vue.prototype.$basicSystemConfigMkglSearch = {
      column:[
        // {columnKey:'key',columnDescription: "搜索",columnType:'input',placeholder:'请输入搜索内容'},
      ],
    }

    // 基础配置 - 系统管理 - 数据权限
    Vue.prototype.$basicSystemConfigSjqxSearch = {
      column:[
        {columnKey:'key',columnDescription: "模块编号",columnType:'input',placeholder:'模块编号'},
      ],
    }

    // 基础配置 - 系统管理 - 角色管理
    Vue.prototype.$basicSystemConfigJsglSearch = {
      column:[
        {columnKey:'key',columnDescription: "角色名称",columnType:'input',placeholder:'角色名称'},
      ],
    }

    // 基础配置 - 系统管理 - 定时任务
    Vue.prototype.$basicSystemConfigDsrwSearch = {
      column:[
        {columnKey:'key',columnDescription: "任务名称",columnType:'input',placeholder:'任务名称'},
      ],
    }

    // 基础配置 - 系统管理 - 用户管理
    Vue.prototype.$basicSystemConfigYhglSearch = {
      column:[
        {columnKey:'key',columnDescription: "姓名",columnType:'input',placeholder:'姓名'},
      ],
    }
    // 基础配置 - 系统管理 - 单位管理
    Vue.prototype.$basicSystemConfigYhglSearchs = {
      column:[
        {columnKey:'key',columnDescription: "单位名称",columnType:'input',placeholder:'单位名称'},
      ],
    }

    // 基础配置 - 系统管理 - 部门管理
    Vue.prototype.$basicSystemConfigBmglSearch = {
      column:[
        {columnKey:'key',columnDescription: "机构名称",columnType:'input',placeholder:'机构名称'},
      ],
    }

    // 基础配置 - 系统管理 - 表单设计
    Vue.prototype.$basicSystemConfigBdsjSearch = {
      column:[
        {columnKey:'key',columnDescription: "名称",columnType:'input',placeholder:'名称'},
      ],
    }

    // 基础配置 - 系统管理 - 流程设计
    Vue.prototype.$basicSystemConfigLcsjSearch = {
      column:[
        {columnKey:'key',columnDescription: "名称",columnType:'input',placeholder:'名称'},
      ],
    }

    // 基础配置 - 代码生成
    Vue.prototype.$dmscSearch = {
      column:[
        {columnKey:'key',columnDescription: "搜索",columnType:'input',placeholder:'请输入搜索内容'},
      ],
    }

    // 报表管理 - 异常记录
    Vue.prototype.$bbglYcjlSearch = {
      column:[
        {columnKey:'surfaceNum',columnDescription: "表编号",columnType:'input',placeholder:'表编号',searchWidth:160,},
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {columnKey:'equipmentAbnormal',columnDescription: "设备异常",columnType:'checkbox',},
        {columnKey:'batteryAbnormal',columnDescription: "电池异常",columnType:'checkbox',},
        {columnKey:'isTotalMeter',columnDescription: "切换总表户表",columnType:'switch',activeText:'总表',inactiveText:'户表',activeColor:'#1a96d4',},
      ],
    }
    
    


    // 仪表总览 - 抄表历史
    Vue.prototype.$ybzlCblsSearch = {
      column:[
        {columnKey:'dateScope',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
      ],
    }






    // 基础资料 - 分支机构管理
    Vue.prototype.$jczlGzjgglSearch = {
      column:[
        {columnKey:'key',columnDescription: "分支机构名称",columnType:'input',placeholder:'请输入分支机构名称',searchWidth:160,},
      ],
    }
    // 基础资料 - 设备管理
    Vue.prototype.$jczlSbglSearch = {
      column:[
        {columnKey:'key',columnDescription: "设备名称",columnType:'input',placeholder:'安装地址/设备编号',searchWidth:200,},
      ],
    }

    // 设备管理 - 设备数据
    Vue.prototype.$sbglSbsjSearch = {
      column:[
        // {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {columnKey:'key',columnDescription: "关键字",columnType:'input',placeholder:'安装地址/设备编号',searchWidth:200,},
        {
          columnKey:'deviceStatisticType',
          columnDescription: "设备类型",
          columnType:'select',
          placeholder:'设备类型',
          seleceOption:[
            {name:'全部',dtCode:'1',},
            {name:'闸门',dtCode:'2',},
            {name:'流量计',dtCode:'3',},
            {name:'水位计',dtCode:'4',},
          ],
          // typeId:'HS_DeviceType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
          searchWidth:200,
        },
      ],
    }
    // 设备管理 - 历史数据
    Vue.prototype.$sbglLssjSearch = {
      column:[
        {columnKey:'queryValue',columnDescription: "关键字",columnType:'input',placeholder:'关键字',searchWidth:120,},
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
      ],
    }
    // 设备管理 - 设备升级
    Vue.prototype.$sjglSbsjSearch = {
      column:[
        {columnKey:'queryValue',columnDescription: "关键字",columnType:'input',placeholder:'关键字',searchWidth:120,},
      ],
    }
    // 设备管理 - 历史数据
    Vue.prototype.$sbgllssjLssjSearch = {
      column:[
        // {columnKey:'key',columnDescription: "关键字",columnType:'input',placeholder:'安装地址/设备编号',searchWidth:200,},
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {
          columnKey:'QueryType',
          columnDescription: "时间段",
          columnType:'select',
          placeholder:'请选择',
          seleceOption:[
            {name:'全部',dtCode:'0',},
            {name:'五分钟',dtCode:'1',},
            {name:'三小时',dtCode:'2',},
            {name:'天',dtCode:'3',},
          ],
          // typeId:'HS_DeviceType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
          searchWidth:200,
        },
      ],
    }
    
    // 数据管理 - 历史数据
    Vue.prototype.$lssjLssjSearch = {
      column:[
        // {columnKey:'key',columnDescription: "关键字",columnType:'input',placeholder:'安装地址/设备编号',searchWidth:200,},
        
        {
          columnKey:'key',
          columnDescription: "关键字",
          columnType:'select',
          placeholder:'请选择设备查询',
          seleceOption:store.state.deviceListData,
          // seleceOption:[
          //   {name:'全部',dtCode:'0',},
          //   {name:'五分钟',dtCode:'1',},
          //   {name:'三小时',dtCode:'2',},
          //   {name:'天',dtCode:'3',},
          // ],
          // typeId:'HS_DeviceType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
          searchWidth:200,
        },
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {
          columnKey:'QueryType',
          columnDescription: "时间段",
          columnType:'select',
          placeholder:'请选择',
          seleceOption:[
            {name:'全部',dtCode:'0',},
            {name:'五分钟',dtCode:'1',},
            {name:'三小时',dtCode:'2',},
            {name:'天',dtCode:'3',},
          ],
          // typeId:'HS_DeviceType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
          searchWidth:200,
        },
      ],
    }
    // 数据管理 - 报警管理
    Vue.prototype.$lssjBjglSearch = {
      column:[
        // {columnKey:'key',columnDescription: "关键字",columnType:'input',placeholder:'安装地址/设备编号',searchWidth:200,},
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {
          columnKey:'QueryType',
          columnDescription: "时间段",
          columnType:'select',
          placeholder:'请选择',
          seleceOption:[
            {name:'全部',dtCode:'0',},
            {name:'五分钟',dtCode:'1',},
            {name:'三小时',dtCode:'2',},
            {name:'天',dtCode:'3',},
          ],
          // typeId:'HS_DeviceType',//从全局数据字典里获取下拉数据
          clearable:false,//能不能清空
          searchWidth:200,
        },
        // {
        //   columnKey:'abnormalState',
        //   columnDescription: "告警类型",
        //   columnType:'select',
        //   placeholder:'告警类型',
        //   seleceOption:[
        //     {name:'时间搜索',dtCode:'1',},
        //     {name:'处理搜索',dtCode:'2',},
        //   ],
        //   typeId:'Hyb_AbnormalState',//从全局数据字典里获取下拉数据
        //   clearable:true,//能不能清空
        // },
        // {
        //   columnKey:'processingStatus',
        //   columnDescription: "处理状态",
        //   columnType:'select',
        //   placeholder:'处理状态',
        //   seleceOption:[
        //     {name:'已经处理',dtCode:'1',},
        //     {name:'未处理',dtCode:'2',},
        //   ],
        //   typeId:'Hyb_ProcessingStatus',//从全局数据字典里获取下拉数据
        //   clearable:true,//能不能清空
        //   searchWidth:100,
        // },
      ],
    }
    // 数据管理 - 巡检记录
    Vue.prototype.$sjglXjjlSearch = {
      column:[
        {columnKey:'timer',columnDescription: "时间筛选",columnType:'datePickerChooseDate',},
        {columnKey:'key',columnDescription: "关键字",columnType:'input',placeholder:'巡查地点/任务名称/巡查设备编号',searchWidth:230,},
      ],
    }
    //  数据管理 - 系统日志
    Vue.prototype.$sjglXtrzSearch = {
      column:[
        {
          columnKey:'logTypeId',
          columnDescription: "日志类型",
          columnType:'select',
          placeholder:'请选择日志类型',
          select:store.state.logType,
          typeId:'SYS_LogType',//从全局数据字典里获取下拉数据
          clearable:true,//能不能清空
        },
        {columnKey:'key',columnDescription: "日志内容",columnType:'input',placeholder:'日志内容'},
      ],
    }


  }
}
