var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    class:
      _vm.isActive === true
        ? "icon iconfont icon-caozuo_suoxiao"
        : "icon iconfont icon-caozuo_quanpingfangda",
    on: { click: _vm.toggleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }