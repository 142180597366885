/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(format) {
  const now = new Date()
  var o = {
    'M+': now.getMonth() + 1, // month
    'd+': now.getDate(), // day
    'h+': now.getHours(), // hour
    'm+': now.getMinutes(), // minute
    's+': now.getSeconds(), // second
    'q+': Math.floor((now.getMonth() + 3) / 3), // quarter
    'S': now.getMilliseconds() // millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1,
      (now.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

// 将list转成tree，使用前注意把array进行深拷贝
// export function listToTreeSelect(data, parent=null, tree=[]) {
  export function listToTreeSelect(data, config) {
  const { id = "id", parentId: pId = "parentId" } = config || {};
      const ids = data.map((_) => _[id]);
      const result = [];
      const waitChecked = [];
      data.forEach((_) => (ids.includes(_[pId]) ? waitChecked : result).push(_));
      const flatData = [...result];
      while (waitChecked.length) {
          const flatIds = flatData.map((_) => _[id]);
          const node = waitChecked.find((_) => flatIds.includes(_[pId]));
          const parNode = flatData.find((_) => _[id] === node[pId]);
          waitChecked.splice(waitChecked.indexOf(node), 1);
          if (parNode.children) {
          parNode.children.push(node);
          } else {
          parNode.children = [node];
          }
          flatData.push(node);
      }
      return result;
  // const children = array.filter(val => val.parentId === (parent ? parent.id : null));

  // if (children.length > 0) {
  //   if (!parent) {
  //     tree = children;
  //   } else {
  //     parent.children = children;
  //   }

  //   children.forEach(val => {
  //     listToTreeSelect(array, val);
  //   });
  // } else if (!parent) {
  //   tree = array;
  // }

  // return tree;
  // tree = typeof tree !== 'undefined' ? tree : []
  // parent = typeof parent !== 'undefined' ? parent : {
  //   id: null
  // }
  // var children = array.filter((val) => {
  //   return val.parentId === parent.id
  // })
  // if (children.length > 0) {
  //   if (parent.id === null) {
  //     tree = children
  //   } else {
  //     parent['children'] = children
  //   }
  //   children.forEach((val) => {
  //     listToTreeSelect(array, val)
  //   })
  // }
  // else if (!parent) {
  //     tree = array;
  //   }
  // return tree
}
// 将list转成tree，使用前注意把array进行深拷贝
export function listToTreeSelectNum(array, parent, tree) {
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : {
    id: null
  }
  var children = array.filter((val) => {
    return val.parentId === parent.id
  })
  if (children.length > 0) {
    if (parent.id === null) {
      tree = children
    } else {
      parent['children'] = children
      parent['childrenNum'] = children.length
    }
    children.forEach((val) => {
      listToTreeSelectNum(array, val)
    })
  }
  return tree
}

// 深拷贝
export const deepClone = (obj) => {
  var objArray = Array.isArray(obj) ? [] : {};
  if(obj && typeof obj === "object") {
    for(let key in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, key)){
        if(obj[key] && typeof obj[key] === "object"){
          objArray[key] = deepClone(obj[key]);
        } else {
          objArray[key] = obj[key];
        }
      }
    }
  }
  return objArray;
}

export const local = {
  setItem(item, data) {
    localStorage.setItem(`INTERNAL_${item}`, JSON.stringify(data))
  },
  getItem(item) {
    return localStorage.getItem(`INTERNAL_${item}`) && JSON.parse(localStorage.getItem(`INTERNAL_${item}`)) || ''
  },
  removeItem(item) {
    localStorage.removeItem(`INTERNAL_${item}`)
  },
  removeAll() {
    localStorage.clear()
  }
}