var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { ref: "appMain", staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _vm.keepAliveDatas.length > 0
            ? _c(
                "keep-alive",
                { attrs: { include: _vm.keepAliveDatas } },
                [_c("router-view", { key: _vm.key })],
                1
              )
            : _c("router-view", { key: _vm.key }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }