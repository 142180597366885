<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'oidcIsAuthenticated'
    ])
  },
  methods: {
    userLoaded: function() {
    }
  },
  mounted() {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)

    //监听浏览器变化，改变拖拽div的宽度
    window.addEventListener('resize',()=>{
      var dragBox = document.getElementsByClassName('dragBox');
      var left = document.getElementsByClassName('dragBox_left');
      var mid = document.getElementsByClassName('dragBox_mid');
      left[0].style.width = this.$store.state.moveLen + 'px';
      mid[0].style.width = (dragBox[0].clientWidth - this.$store.state.moveLen - 10) + 'px';
    });
  },
  destroyed() {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
  }
}

</script>
