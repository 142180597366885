import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import tenant from './modules/tenant'
import form from './modules/form'
import flowschemes from './modules/flowschemes'
import flowinstances from './modules/flowinstances'
import serverConf from './modules/serverConf'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import dataPrivilegerules from './modules/dataPrivilegerules'
import storage from './modules/storage'
import flow from './modules/flow'
import getters from './getters'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'

Vue.use(Vuex)
const store = new Vuex.Store({
  state:{
    userId:'',//用户userId
    

    // 通用
    upLoadTypeData:[],//获取上传的类型

    // 华德仪表数据
    allCategorysData:[],//全部字典类型
    AllUser:[],//全部用户
    AllArea:[],//全部区域
    AllAreaNoSurface:[],//全部区域,剔除区域下户表为0的区域
    hasSurface:true,//是否隐藏户表为0的区域
    AllProject:[],//全部项目
    AllEquipmentType:[],//获取全部类型规格
    AllAreaGetTreeId:[],//全部区域（未转换tree），用于获取全部id
    TemplateType:[],//导入模板类型
    moveLen:240,//拖动div左侧默认宽度
    userCode:"",
    deviceType:[],//设备类型
    cropType:[],//作物类型
    allInstitution:[],//所有机构
    sbxxTimer:'',//设备信息定时器
    useryn:[],//是否可用
    setTimeoutId:null,//定时器id
    deviceListData:[],//设备数据
  },
  mutations:{
    updataListLoading(state,bool){
      state.listLoading=bool
    },
  },
  modules: {
    app,
    form,
    flowschemes,
    flowinstances,
    user,
    serverConf,
    permission,
    dataPrivilegerules,
    storage,
    tagsView,
    flow,
    tenant,
    oidcStore: vuexOidcCreateStoreModule(
      {
        authority: process.env.VUE_APP_OIDC_AUTHORITY,
        clientId: process.env.VUE_APP_OIDC_CLIENTID,
        redirectUri: process.env.VUE_APP_OIDC_REDIRECTURI,
        postLogoutRedirectUri:process.env.VUE_APP_OIDC_POSTLOGOUTREDIRECTURI,
        responseType: process.env.VUE_APP_OIDC_RESPONSETYPE,
        scope: process.env.VUE_APP_OIDC_SCOPE,
        automaticSilentRenew: process.env.VUE_APP_OIDC_AUTOMATICSILENTRENEW ,
        silentRedirectUri: process.env.VUE_APP_OIDC_SILENTREDIRECTURI
      },
      // Optional OIDC store settings
      {
        namespaced: false,
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => console.log('OIDC user is loaded:', user),
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out')
      }
    ),
    
  },
  getters
})

export default store
