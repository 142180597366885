import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/sysMessages/load',
    method: 'get',
    params
  })
}

export function read(data) {
  return request({
    url: '/sysMessages/read',
    method: 'post',
    data
  })
}

export function allRead(data) {
  return request({
    url: '/SysMessages/ReadAllList',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/sysMessages/delete',
    method: 'post',
    data
  })
}

//获取在线联系人接口
export function getUserOnline(params) {
  return request({
    url: '/SysMessages/GetUserOnline',
    method: 'get',
    params
  })
}
