var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    { staticClass: "navbar", attrs: { mode: "horizontal" } },
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", { staticClass: "user-avatar", attrs: { src: _vm.logo } }),
        _vm._v("远传监控平台 "),
      ]),
      _c(
        "el-tooltip",
        {
          attrs: {
            effect: "light",
            content: _vm.sidebar.opened === true ? "收缩菜单" : "展开菜单",
            placement: "right-end",
          },
        },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              toggleClick: _vm.toggleSideBar,
              isActive: _vm.sidebar.opened,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "navbar_right" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: { trigger: "hover", placement: "top" },
              on: { command: _vm.handleCommand },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm._v(" 欢迎您，" + _vm._s(_vm.name) + " "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "handleGoProfile" } },
                    [
                      _c("span", {
                        staticClass: "personal_icon iconfont icon-jiaoseguanli",
                      }),
                      _c("span", [_vm._v("个人中心")]),
                    ]
                  ),
                  _c("el-dropdown-item", [
                    _c("span", {
                      staticClass: "personal_icon iconfont icon-pifu",
                    }),
                    _c(
                      "span",
                      [
                        _vm._v("切换主题 "),
                        _c("el-switch", {
                          staticClass: "swith_css",
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: _vm.theme,
                            callback: function ($$v) {
                              _vm.theme = $$v
                            },
                            expression: "theme",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "logout", divided: "" } },
                    [
                      _c("span", {
                        staticClass: "personal_icon iconfont icon-tuichu",
                      }),
                      _c("span", [_vm._v("退出登录")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "clear" }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: {
            width: "30%",
            title: _vm.messageForm.typeName,
            visible: _vm.messageDialog,
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.messageDialog = $event
            },
          },
        },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.messageForm.content) + " ")]),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.messageDialog = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }