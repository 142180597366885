var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tags-view-container" },
    [
      _c(
        "scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        _vm._l(Array.from(_vm.visitedViews), function (tag) {
          return _c(
            "router-link",
            {
              key: tag.fullPath,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: { to: tag.fullPath },
              nativeOn: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                },
              },
            },
            [
              _vm._v(" " + _vm._s(tag.title) + " "),
              _c("span", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.closeSelectedTag(tag)
                  },
                },
              }),
            ]
          )
        }),
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeSelectedTag(_vm.selectedTag)
                },
              },
            },
            [_vm._v("关闭")]
          ),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _vm._v("关闭其他"),
          ]),
          _c("li", { on: { click: _vm.closeAllTags } }, [_vm._v("全部关闭")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }