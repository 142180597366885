/* * 
 * @description: 通用表单
 * @author: Long
 * @version: 1.0
 * @updateDate:2022-2-11
 * @description: 通用的表单项，定义如下：
    columnKey：参数名
    columnDescription：中文名（描述）
    columnType：参数类型
      text：文本（input）      selectConversion：通过字典转换的select选择器      auto：自动完成（远程搜索）选择器      date：日期      datetime：时间      textarea：多行文本
 * */
 export default {
  install(Vue) {
    // 采购模块-采购入库-到货通知单
    Vue.prototype.$purchaseCgrkDhtzd = {
      column:[
        // {columnKey:'stockCode',columnDescription: "仓库",columnType:'select'},//测试仓库
        {columnKey:'orderCode',columnDescription: "到货通知单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'supplierName',columnDescription: "供应商",columnType:'auto',},
        {columnKey:'scheduledDate',columnDescription: "预定到货日期",columnType:'date',},
        {columnKey:'actualDate',columnDescription: "实际入库日期",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
        {columnKey:'remark',columnDescription: "备注",columnType:'textarea',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "到货通知单号不能为空", trigger: "blur" }, ],
      }
    }
    

    // 采购模块-采购入库-入库申请
    Vue.prototype.$purchaseCgrkRksq = {
      column:[
        {columnKey:'orderCode',columnDescription: "入库申请单号",columnType:'text',},
        {columnKey:'orderType',columnDescription: "入库类型",columnType:'selectConversion',disabled:true},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',disabled:true},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',disabled:true},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "入库申请单号不能为空", trigger: "blur" }, ],
      }
    }
    

    // 采购模块-采购入库-上架任务
    Vue.prototype.$purchaseCgrkSjrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "上架任务号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'relatedCode',columnDescription: "入库申请单号",columnType:'text',},
        {columnKey:'pickBeginTime',columnDescription: "上架开始时间",columnType:'date',},
        {columnKey:'pickEndTime',columnDescription: "上架结束时间",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "上架任务号不能为空", trigger: "blur" }, ],
      }
    }
    

    // 采购模块-采购退货-退货通知单
    Vue.prototype.$purchaseCgthThtzd = {
      column: [
        {columnKey:'orderCode',columnDescription: "退货通知单号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'supplierName',columnDescription: "供应商",columnType:'auto',},
        {columnKey:'relatedCode',columnDescription: "到货通知单号",columnType:'text',},
        {columnKey:'scheduledDate',columnDescription: "预定出库日期",columnType:'date',},
        {columnKey:'actualDate',columnDescription: "实际出库日期",columnType:'date',},
        {columnKey:'shipperId',columnDescription: "承运人编号",columnType:'text',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "退货通知单号不能为空", trigger: "blur" }, ],
      }
    }
   

    // 采购模块-采购退货-退货申请
    Vue.prototype.$purchaseCgthThsq = {
      column:[
        {columnKey:'orderCode',columnDescription: "退货申请单号",columnType:'text',},
        {columnKey:'outStockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'externalPoId',columnDescription: "物流单号",columnType:'text',},
        {columnKey:'planOutPickTime',columnDescription: "计划退货日期",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "退货申请单号不能为空", trigger: "blur" }, ],
      }
    }
    

    // 采购模块-采购退货-退货任务
    Vue.prototype.$purchaseCgthThrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "拣货任务单号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'relatedCode',columnDescription: "退货申请单号",columnType:'text',},
        {columnKey:'pickingStartTime',columnDescription: "拣选开始时间",columnType:'date',},
        {columnKey:'pickingEndTime',columnDescription: "拣选结束时间",columnType:'date',},
        {columnKey:'planTime',columnDescription: "计划退货日期",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "拣货任务单号不能为空", trigger: "blur" }, ],
      }
    }
    

    // 生产模块-领料出库-领料申请
    Vue.prototype.$productionLlckLlsq = {
      column:[
        {columnKey:'orderCode',columnDescription: "出库申请单号",columnType:'text',disabled:true},
        {columnKey:'outStockType',columnDescription: "出库类型",columnType:'selectConversion',disabled:true},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',disabled:true},
        {columnKey:'planPrepareTime',columnDescription: "计划备货日期",columnType:'date',},
        {columnKey:'planOutPickTime',columnDescription: "计划领料日期",columnType:'date',},
        {columnKey:'sourceType',columnDescription: "来源类型",columnType:'selectConversions'},
        {columnKey:'remark',columnDescription: "备注",columnType:'textarea', disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'出库申请单号不能为空',trigger:'blur'  }],
        id:[{  required:true,message:'仓库申请单号不能为空',trigger:'blur'  }],
        outStockType:[{  required:true,message:'出库类型不能为空',trigger:'blur'  }],
        sourceType:[{  required:true,message:'来源类型不能为空',trigger:'blur'  }],
      }
    }
    
    
    // 生产模块-领料出库-备货任务
    Vue.prototype.$productionLlckBhrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "备货任务单号",columnType:'text',disabled:true},
        {columnKey:'applyType',columnDescription: "申请类型",columnType:'selectConversion',disabled:true},
        {columnKey:'applyReponse',columnDescription: "申请原因",columnType:'selectConversion',disabled:true},
        {columnKey:'status',columnDescription: "申请单状态",columnType:'selectConversion',disabled:true},
        {columnKey:'externalType',columnDescription: "相关单据类型",columnType:'selectConversion',disabled:true},
        {columnKey:'externalNo',columnDescription: "相关单据号",columnType:'text',disabled:true},
        {columnKey:'remark',columnDescription: "备注",columnType:'text', disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode: [{ 	required: true, message: '库内任务单号不能为空', trigger: 'blur' }],
        stockCode: [{ required: true, message: '仓库ID不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '仓库申请单状态不能为空', trigger: 'blur' }],
        applyType: [{ required: true, message: '申请类型不能为空', trigger: 'blur' }],
        enable: [{ required: true, message: '有效标志不能为空', trigger: 'blur' }],
      }
    }
    

    // 生产模块-领料出库-拣货任务
    Vue.prototype.$productionLlckJhrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "拣货任务号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'externalNo',columnDescription: "出库申请单号",columnType:'text',},
        {columnKey:'pickingStartTime',columnDescription: "拣货开始时间",columnType:'date',},
        {columnKey:'pickingEndTime',columnDescription: "拣货结束时间",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
        {columnKey:'remark',columnDescription: "备注",columnType:'text', disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'拣货任务号不能为空',trigger:'blur'  }],
        outstockType:[{  required:true,message:'出库类型不能为空',trigger:'blur'  }],
        status:[{  required:true,message:'拣货状态不能为空',trigger:'blur'  }],
      }
    }
    

    // 生产模块-退料入库-退料申请
    Vue.prototype.$productionTlrkTlsq = {
      column:[
        {columnKey:'orderCode',columnDescription: "退料申请单号",columnType:'text',},
        {columnKey:'orderType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
        {columnKey:'remark',columnDescription: "备注",columnType:'textarea', disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'退料申请单号不能为空',trigger:'blur'  }],
        orderType: [{ required: true, message: '入库类型(SYS_INSTCTYPE)不能为空', trigger: 'blur' }],     
        stockCode: [{ required: true, message: '仓库编号不能为空', trigger: 'blur' }],
      }
    }
    

    // 生产模块-退料入库-退料任务
    Vue.prototype.$productionTlrkTlrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "退料任务单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'externalNo',columnDescription: "退料申请单号",columnType:'text',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'退料任务单号不能为空',trigger:'blur'  }],
        stockCode: [{ required: true, message: '仓库编号不能为空', trigger: 'blur' }],
      }
    }
    

    // 生产模块-成品入库-入库申请
    Vue.prototype.$productionCprkRksq = {
      column:[
        {columnKey:'orderCode',columnDescription: "入库申请单号",columnType:'text',disabled:true},
        {columnKey:'orderType',columnDescription: "入库类型",columnType:'selectConversion',disabled:true},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',disabled:true},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',disabled:true},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'入库申请单号不能为空',trigger:'blur'  }],
        orderType: [{ required: true, message: '入库类型(SYS_INSTCTYPE)不能为空', trigger: 'blur' }],     
        stockCode: [{ required: true, message: '仓库编号不能为空', trigger: 'blur' }],
      }
    }
    

    // 生产模块-成品入库-上架任务
    Vue.prototype.$productionCprkSjrw= {
      column:[
        {columnKey:'orderCode',columnDescription: "上架任务号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'externalNo',columnDescription: "入库申请单号",columnType:'text',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'上架任务号不能为空',trigger:'blur'  }],
        stockCode: [{ required: true, message: '仓库编号不能为空', trigger: 'blur' }],
      }
    }
    

    // 仓储中心-支给品管理-入库申请
    Vue.prototype.$zgpglRksq = {
      column:[
        {columnKey:'orderCode',columnDescription: "入库申请单号",columnType:'text',disabled:true},
        {columnKey:'orderType',columnDescription: "入库类型",columnType:'selectConversion',disabled:true},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',disabled:true},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',disabled:true},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',disabled:true},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'入库申请单号不能为空',trigger:'blur'  }],
      }
    }
    

    // 仓储中心-支给品管理-上架任务
    Vue.prototype.$zgpglSjrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "入库任务号",columnType:'text',},
        {columnKey:'externalNo',columnDescription: "入库申请单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'pickBeginTime',columnDescription: "入库开始时间",columnType:'date',},
        {columnKey:'pickEndTime',columnDescription: "入库结束时间",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'入库任务号不能为空',trigger:'blur'  }],
      }
    }

    // 仓储中心-支给品管理-出库申请
    Vue.prototype.$zgpglCksq = {
      column:[
        {columnKey:'orderCode',columnDescription: "出库申请单号",columnType:'text',},
        {columnKey:'outStockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'出库申请单号不能为空',trigger:'blur'  }],
      }
    }

    // 仓储中心-支给品管理-拣货任务
    Vue.prototype.$zgpglJhrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "出库任务号",columnType:'text',},
        {columnKey:'externalNo',columnDescription: "出库申请单号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'pickingStartTime',columnDescription: "拣货开始时间",columnType:'date',},
        {columnKey:'pickingEndTime',columnDescription: "拣货结束时间",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode:[{  required:true,message:'出库任务号不能为空',trigger:'blur'  }],
      }
    }
    

    // // 仓储中心-仓库盘点-盘点计划 - (页面需要特殊处理，这里不用基础组件)
    // Vue.prototype.$storageCenterInventoryPdjh = [
    //   {columnKey:'orderCode',columnDescription: "盘点计划号",columnType:'text',},
    //   {columnKey:'checkType',columnDescription: "盘点类型",columnType:'selectConversion',},
    //   {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
    //   {columnKey:'stockCode',columnDescription: "盘点仓库",columnType:'select',},
    //   {columnKey:'goodsPosCode',columnDescription: "盘点库位",columnType:'select',},
    //   {columnKey:'goodsAreaCode',columnDescription: "盘点库区",columnType:'select',},
    // ]

    // 仓储中心-仓库盘点-盘点任务
    Vue.prototype.$storageCenterInventoryPdrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "盘点任务单号",columnType:'text',},
        {columnKey:'applyType',columnDescription: "单据类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'stockCode',columnDescription: "盘点仓库",columnType:'select',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ 	required: true, 	message: "库内任务单号不能为空", trigger: "blur" }, ],
        stockCode: [{ required: true, message: "仓库不能为空", trigger: "blur" 	}],
        status: [{ 	required: true, message: "仓库申请单状态不能为空", trigger: "blur", 	}, ],
        applyType: [{ required: true, message: "申请类型不能为空", trigger: "blur" 	}, ],
        enable: [{ 	required: true, message: "有效标志不能为空", trigger: "blur" 	}, ],
      }
    }
    

    // 仓储中心-其他发货
    Vue.prototype.$storageCenterQtfh = {
      column:[
        {columnKey:'orderCode',columnDescription: "其他发货单号",columnType:'text',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'applyReponse',columnDescription: "申请原因",columnType:'selectConversion',special:true},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: "仓库申请单号不能为空", trigger: "blur" }, ],
        status: [{ required: true, message: "仓库申请单状态不能为空", trigger: "blur", },],
        stockCode: [{ equired: true, message: "仓库ID不能为空", trigger: "blur" }, ],
        createTime: [{ required: true, message: "创建时间不能为空", trigger: "blur" }, ],
        createUserName: [{ required: true, message: "创建人不能为空", trigger: "blur" }, ],
        applyType: [{ required: true, message: "申请类型不能为空", trigger: "blur" }, ],
        enable: [{ required: true, message: "有效标志不能为空", trigger: "blur", }, ],
        createUserId: [{ required: true, message: "创建人ID不能为空", trigger: "blur" }, ],
      }
    }
    

    // 仓储中心-其他收货
    Vue.prototype.$storageCenterQtsh = {
      column:[
        {columnKey:'orderCode',columnDescription: "其他收货单号",columnType:'text',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'applyReponse',columnDescription: "申请原因",columnType:'selectConversion',special:true},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
        {columnKey:'remark',columnDescription: "备注",columnType:'textarea',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '仓库申请单号不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '仓库申请单状态不能为空', trigger: 'blur' }],
        stockCode: [{ required: true, message: '仓库不能为空', trigger: 'blur' }],
        applyType: [{ required: true, message: '申请类型不能为空', trigger: 'blur' }],
        enable: [{ required: true, message: '有效标志不能为空', trigger: 'blur' }],
        applyReponse: [{ required: true, message: '请选择申请原因', trigger: 'blur' }],
      }
    }
    

    // 销售模块-发货出库-发货通知单
    Vue.prototype.$saleFhckFhtzd = {
      column:[
        {columnKey:'orderCode',columnDescription: "发货通知单号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'scheduledDate',columnDescription: "预定出库日期",columnType:'date',},
        {columnKey:'actualDate',columnDescription: "实际出库日期",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '到货通知单号不能为空', trigger: 'blur' }],
        outstockType: [{ required: true, message: '入库类型不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '到货通知单状态不能为空', trigger: 'blur' }],
      }
    }
    

    // 销售模块-发货出库-发货申请单
    Vue.prototype.$saleFhckFhsqd = {
      column:[
        {columnKey:'orderCode',columnDescription: "发货申请单号",columnType:'text',disabled:true,},
        {columnKey:'outStockType',columnDescription: "出库类型",columnType:'selectConversion',disabled:true,},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',disabled:true,},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '出库申请单号不能为空', trigger: 'blur' }],
        outStockType: [{ required: true, message: '出库类型(SYS_OUTSTCTYPE)不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '仓库申请单状态不能为空', trigger: 'blur' }],
      }
    }
    

    // 销售模块-发货出库-拣货任务
    Vue.prototype.$saleFhckJhrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "拣货任务单号",columnType:'text',},
        {columnKey:'outstockType',columnDescription: "出库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '拣选任务号不能为空', trigger: 'blur' }],
        outstockType: [{ required: true, message: '出库类型不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '拣选状态不能为空', trigger: 'blur' }],
      }
    }
    

    // 销售模块-退货入库-退货通知单
    Vue.prototype.$saleThrkThtzd = {
      column:[
        {columnKey:'orderCode',columnDescription: "退货通知单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'scheduledDate',columnDescription: "预定入库日期",columnType:'date',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '退货通知单号不能为空', trigger: 'blur' }],
      }
    }
    
    
    // 销售模块-退货入库-退货申请单
    Vue.prototype.$saleThrkThsqd = {
      column:[
        {columnKey:'orderCode',columnDescription: "退货申请单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '退货申请单号不能为空', trigger: 'blur' }],
      }
    }
    
    
    // 销售模块-退货入库-退货任务
    Vue.prototype.$saleThrkThrw = {
      column:[
        {columnKey:'orderCode',columnDescription: "退货任务单号",columnType:'text',},
        {columnKey:'instockType',columnDescription: "入库类型",columnType:'selectConversion',},
        {columnKey:'status',columnDescription: "单据状态",columnType:'selectConversion',},
        {columnKey:'createUserName',columnDescription: "创建人",columnType:'text',},
        {columnKey:'createTime',columnDescription: "创建时间",columnType:'date',},
      ],
      //校验
      ruleInline:{
        orderCode: [{ required: true, message: '退货任务单号不能为空', trigger: 'blur' }],
      }
    }
    

  }
}
