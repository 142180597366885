var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.state.listLoading,
          expression: "$store.state.listLoading",
        },
      ],
      staticClass: "app-wrapper",
      class: _vm.classObj,
    },
    [
      _c(
        "el-container",
        { staticClass: "flex-column" },
        [
          _c(
            "el-header",
            { staticStyle: { "z-index": "2001" }, attrs: { height: "45px" } },
            [_c("navbar")],
            1
          ),
          _c(
            "el-container",
            { staticClass: "flex-row flex-item" },
            [
              _c("sidebar", { staticClass: "sidebar-container" }),
              _c(
                "div",
                { staticClass: "main-container flex-item" },
                [_c("app-main")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }