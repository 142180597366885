export  function  numDigit(standNum){
  if(standNum==""||standNum==null){
    return 0
  }
  var y = String(standNum).indexOf(".") + 1;
  var count = String(standNum).length - y;//获取小数点后的个数
  if(y > 0) {
    //是小数
    return count
  } else {
    //不是小数
    return 0
  }
}

//云完成根据 是否禁用 以及 小数 进行取值
export  function getTask(num,row){
  if(row.dis!=null||row.dis!=false){
    if(row.xiaoshu!=null){
      return parseFloat(num).toFixed(row.xiaoshu)
    }else{
      return num
    }
  }else{
    return num
  }
}
// 命令判断是否通过 命令状态 -1 出错（例如参数错）， 0 已接受， 1 成功， 2 正在处理， >=3 出错 3错误（通用） 4 重试超过次数 5 超时   
export function commandGudgment(command){   //0失败 1正在处理 2成功
  if(command==-1 || command>=3){
    return 0
  }else if(command ==0 || command==2){
    return 1
  }else if(command ==1){
    return 2
  }
}