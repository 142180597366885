var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticStyle: { border: "1px solid #eee" } },
    [
      _c(
        "el-main",
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                "label-width": "80px",
                model: _vm.temp,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请假类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.requestType,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "requestType", $$v)
                        },
                        expression: "temp.requestType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "病假" } }),
                      _c("el-radio", { attrs: { label: "事假" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请假人", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { name: "name", disabled: _vm.disabled("userName") },
                    model: {
                      value: _vm.temp.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "userName", $$v)
                      },
                      expression: "temp.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("startDate"),
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.temp.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "startDate", $$v)
                          },
                          expression: "temp.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                    _vm._v("-"),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("startTime"),
                          type: "fixed-time",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.temp.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "startTime", $$v)
                          },
                          expression: "temp.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("endDate"),
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.temp.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "endDate", $$v)
                          },
                          expression: "temp.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                    _vm._v("-"),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c("el-time-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled("endTime"),
                          type: "fixed-time",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.temp.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "endTime", $$v)
                          },
                          expression: "temp.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    size: "small",
                    label: "请假说明",
                    prop: "requestComment",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: _vm.disabled("requestComment"),
                      rows: 3,
                    },
                    model: {
                      value: _vm.temp.requestComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requestComment", $$v)
                      },
                      expression: "temp.requestComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        "on-change": _vm.handleChange,
                        action: _vm.baseURL + "/Files/Upload",
                        "before-remove": _vm.beforeRemove,
                        multiple: "",
                        name: "files",
                        limit: 3,
                        "list-type": "picture",
                        "on-exceed": _vm.handleExceed,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("上传附加，如医院就诊记录")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("只能上传不超过1Mb")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }