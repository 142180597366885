//接口api ==> ERP js
//公共请求地址：VUE_APP_ERP_API = http://192.168.0.116:52789/api    ，在.env.dev中配置

/* 使用说明：
  方法“()”前需要加上“async”
  请求前需要加上“await”
  例：
  async created() {
    const {data:res} = await this.$autGet(this.listQuery, this.$getUserDataUrl);
    console.log(res,123)
  }, 

*/

export default{
  install(Vue){
    // 测试接口
    Vue.prototype.$cgrksqdPostSyncErp = process.env.VUE_APP_BASE_API + '/WmsPurseArrivalIn/GenErpInStock'
  }

}


